import React from 'react';

import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { withTranslation, Trans } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { fetchNbReports } from '../../api/search';
import { fetchNbImput } from '../../api/products';
import { formatNumber } from '../../utils/formatNumber';

class HistoryTest extends React.Component {
    constructor(props) {
        super(props);
        let target = 'medicament';
        if (props.data.type !== undefined) {
            switch(props.data.type) {
                case 'Dispositif médical' : target = "dispositif"; break;
                case 'Cosmétique' : target = "cosmetique"; break;
                case 'Complément alimentaire' : target = "complement"; break;
                case 'Médicament' : target = "medicament"; break;
                default: break;
            }
        }

        this.state = {
            resultat: props.data.resultat !== undefined && props.data.resultat !== null ? props.data.resultat : null,
            data: props.data !== undefined && props.data !== null ? props.data : {},
            target,
            connected: props.connected !== undefined ? props.connected : false,
            premium: props.premium !== undefined ? props.premium : false,
            nbReports: null,
        }
    }

    componentDidMount() {
        if (this.props.data.type === "Médicament") {
            this.props.mutationNbOfReports.mutate(this.state.data.nomProduit);
        } else {
            this.props.mutationNbOfImputVigicare.mutate(this.state.data.nomProduit);
        }
    }

    componentDidUpdate() {
        
        if (this.state.data.type === "Médicament") {
            if (this.state.nbReports === null && this.props.mutationNbOfReports.isSuccess) {
                this.setState({nbReports: formatNumber(this.props.mutationNbOfReports.data.nbOfReports)});
            }
            if (this.state.nbReports === null && this.props.mutationNbOfReports.isError){
                this.setState({nbReports: 0});
            }
        } else {
            if (this.state.nbReports === null && this.props.mutationNbOfImputVigicare.isSuccess) {
                this.setState({nbReports: formatNumber(this.props.mutationNbOfImputVigicare.data.nb)});
            }
            if (this.state.nbReports === null && this.props.mutationNbOfImputVigicare.isError){
                this.setState({nbReports: 0});
            }
        }
    }

    render () {
        const { target, resultat, data, premium, nbReports } = this.state;        
        const { t } = this.props;

        return <div>
                <p className='resultatTitleHistory'>{t("adverseEffect.result.title")} : {data.nomProduit}</p>
                    {resultat === null ? 
                        <>
                            <p className='errorText'>{t("adverseEffect.result.testNotFound")}</p>
                            <Button variant='outlined' onClick={this.props.handler}>{t("adverseEffect.result.action.back")}</Button>
                        </>
                        
                    : <>
                    {resultat < 1 ? <>
                        <div className='resultatHead'>
                            <div className='resultatTitle'><CircleIcon color='yellowDot'/><p>{t("adverseEffect.result.score.doubtful.title")}</p></div>
                            <p className='resultatScore'>{t("adverseEffect.result.score.doubtful.subtitle",{value: '0'})}</p>
                        </div>
                        
                        <div className='resultRow'>
                            <CheckIcon fontSize='large' color='grey' />
                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.score.doubtful.message">Following your answers, <b>your symptoms seem slightly linked</b> to the use of described product(s).</Trans></p>
                        </div>
                        <div className='resultRow'>
                            <MedicalInformationIcon fontSize='large' color='blue.dark' />
                            <p>{t("adverseEffect.result.score.doubtful.description")}</p>
                        </div>
                        <div className='resultRow'>
                            <QueryStatsIcon fontSize='large' color='premium' />
                            {premium ?
                              <p><Trans i18nKey="adverseEffect:adverseEffect.result.search" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. <Link className='premiumLinks' to='/recherche'>Search this product</Link></Trans></p>
                            : <p><Trans i18nKey="adverseEffect:adverseEffect.result.subscription" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. You can learn more by subscribing to</Trans><Link className='premiumLinks' target="_blank" to='/premium'>{" "}Vigicare PREMIUM</Link></p>}
                        </div>
                        <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>{t("adverseEffect.result.action.declare")}</Button></div></Link>
                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>{t("adverseEffect.result.action.report")}</Button></div></a>
                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>{t("adverseEffect.result.action.back")}</Button></div>
                        </div>
                    </> 
                        : <>{resultat < 5 ? 
                                <>
                                    <div className='resultatHead'>
                                        <div className='resultatTitle'><CircleIcon color='orangeDot'/><p>{t("adverseEffect.result.score.possible.title")}</p></div>
                                        <p className='resultatScore'>{t("adverseEffect.result.score.possible.subtitle",{startValue: '1', endValue: "4"})}</p>
                                    </div>
                                    
                                    <div className='resultRow'>
                                        <CheckIcon fontSize='large' color='grey' />
                                        <p><Trans i18nKey="adverseEffect:adverseEffect.result.score.possible.message">You described adverse reactions that must not be taken slightly! Following your answers and the international Naranjo algorithm, <b>your symptoms might be linked</b> with the use of described product(s).</Trans></p>
                                    </div>
                                    <div className='resultRow'>
                                        <MedicalInformationIcon fontSize='large' color='blue' />
                                        <p>{t("adverseEffect.result.score.possible.description")}</p>
                                    </div>
                                    <div className='resultRow'>
                                        <QueryStatsIcon fontSize='large' color='premium' />
                                        {premium ?
                                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.search" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. <Link className='premiumLinks' to='/recherche'>Search this product</Link></Trans></p>
                                        : <p><Trans i18nKey="adverseEffect:adverseEffect.result.subscription" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. You can learn more by subscribing to</Trans><Link className='premiumLinks' target="_blank" to='/premium'>{" "}Vigicare PREMIUM</Link></p>}
                                    </div>
                                    <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                                        <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>{t("adverseEffect.result.action.declare")}</Button></div></Link>
                                        <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>{t("adverseEffect.result.action.report")}</Button></div></a>
                                        <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>{t("adverseEffect.result.action.back")}</Button></div>
                                    </div>
                                </> 
                                : <> {resultat < 9 ? 
                                    <>
                                        <div className='resultatHead'>
                                            <div className='resultatTitle'><CircleIcon color='redDot' sx={{ pt: '1em'}}/><p>{t("adverseEffect.result.score.probable.title")}</p></div>
                                            <p className='resultatScore'>{t("adverseEffect.result.score.probable.subtitle",{startValue: '5', endValue: "8"})}</p>
                                        </div>
                                        <div className='resultRow'>
                                            <CheckIcon fontSize='large' color='grey' />
                                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.score.probable.message">You described adverse reactions that must not be taken slightly! Following your answers and the international Naranjo algorithm, <b>your symptoms seem linked</b> with the use of described product(s).</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <MedicalInformationIcon fontSize='large' color='blue' />
                                            <p>{t("adverseEffect.result.score.probable.description")}</p>
                                        </div>
                                        <div className='resultRow'>
                                            <QueryStatsIcon fontSize='large' color='premium' />
                                        {premium ?
                                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.search" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. <Link className='premiumLinks' to='/recherche'>Search this product</Link></Trans></p>
                                        : <p><Trans i18nKey="adverseEffect:adverseEffect.result.subscription" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. You can learn more by subscribing to</Trans><Link className='premiumLinks' target="_blank" to='/premium'>{" "}Vigicare PREMIUM</Link></p>}
                                        </div>
                                        <div className='bottomBtnQuestionnaire bottomBtnHisto' >
                                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>{t("adverseEffect.result.action.declare")}</Button></div></Link>
                                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>{t("adverseEffect.result.action.report")}</Button></div></a>
                                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>{t("adverseEffect.result.action.back")}</Button></div>
                                        </div>
                                    </> 
                                    : <>
                                        <div className='resultatHead'>
                                            <div className='resultatTitle'><CircleIcon color='redDotDark'/><p>{t("adverseEffect.result.score.definite.title")}</p></div>
                                            <p className='resultatScore'>{t("adverseEffect.result.score.definite.subtitle",{value:'9'})}</p>
                                        </div>
                                        
                                        <div className='resultRow'>
                                            <CheckIcon fontSize='large' color='grey' />
                                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.score.definite.message">You described adverse reactions that must not be taken slightly! Following your answers and the international Naranjo algorithm, <b>your symptoms are linked</b> with the use of described product(s)</Trans></p>
                                        </div>
                                        <div className='resultRow'>
                                            <MedicalInformationIcon fontSize='large' color='blue' />
                                            <p>{t("adverseEffect.result.score.definite.description")}</p>
                                        </div>
                                        <div className='resultRow'>
                                            <QueryStatsIcon fontSize='large' color='premium' />
                                        {premium ?
                                            <p><Trans i18nKey="adverseEffect:adverseEffect.result.search" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. <Link className='premiumLinks' to='/recherche'>Search this product</Link></Trans></p>
                                        : <p><Trans i18nKey="adverseEffect:adverseEffect.result.subscription" count={nbReports}>To this day, <b>{nbReports !== null ? nbReports : 0} adverse reactions</b> have been declared for this product. You can learn more by subscribing to</Trans><Link className='premiumLinks' target="_blank" to='/premium'>{" "}Vigicare PREMIUM</Link></p>}
                                        </div>

                                        <div className='bottomBtnQuestionnaire bottomBtnHisto'>
                                            <Link to={'/declaration/' + target}><div className="historyTestBtn1"><Button variant='contained'>{t("adverseEffect.result.action.declare")}</Button></div></Link>
                                            <a href='https://solidarites-sante.gouv.fr/soins-et-maladies/signalement-sante-gouv-fr/' target="_blank" rel='noreferrer'><div className="historyTestBtn2"><Button variant='outlined'>{t("adverseEffect.result.action.report")}</Button></div></a>
                                            <div className="historyTestBtn3" onClick={this.props.handler}><Button variant='outlined' sx={{  color: '#071B2E !important', borderColor: '#D9D9D9 !important' }}>{t("adverseEffect.result.action.back")}</Button></div>
                                        </div>
                                </>}
                            </>
                        }</>
                    }  </>}
            </div>
    }
};

export default withQuery(withTranslation("adverseEffect")(HistoryTest));

function withQuery( Child ) {
    return ( props ) => {
        const mutationNbOfReports = useMutation({
            mutationFn: (product) => {
                return fetchNbReports(product).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });
        const mutationNbOfImputVigicare = useMutation({
            mutationFn: (product) => {
                return fetchNbImput(product).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });

        return <Child { ...props } mutationNbOfReports={mutationNbOfReports} mutationNbOfImputVigicare={mutationNbOfImputVigicare} />;
    }
}