import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import { VigicareLogoWhite } from '../../styles/Icons';

import { ThemeProvider, Grid, Button  } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';
import Popup from 'reactjs-popup';

import { useMutation, useQuery } from '@tanstack/react-query';
import { newDeclaration } from '../../api/history';
import { getUser } from '../../api/user';

import Infos from '../sharedComponents/Infos';
import Pays from '../sharedComponents/Pays';
import DeclarationValidePDF from '../sharedComponents/DeclarationValidePDF';

import Emetteur from './forms/Emetteur';
import DispositifMedical from './forms/DispositifMedical';
import Incident from './forms/Incident';

import { neutralizeBack } from '../../misc/navigation';

import './dispositif.css' 
import moment from 'moment';
    
class Dispositif extends React.Component {
    constructor (props) {
        super(props);
        let userdata = {};
        if (props.queryUser.isSuccess && this.props.queryUser.data !== undefined && this.props.queryUser.data.length > 0) {
            userdata = {
                userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode, 
                    dateFin: this.props.queryUser.data[0].dateFin,
            }
        };
        if (props.params !== undefined && props.params !== null) {
            this.state = {
                activePage: 0,
                infoPage: false,
                emetteur: {},
                dispoMed: {
                    denominationDM: props.params.produit.name,
                    denominationComDM: props.params.produit.marqueProduit,
                    nomFabricant: props.params.produit.nomFabricant,
                },
                incident: {
                    date: props.params.effets.dateEffet !== '' ? moment(props.params.effets.dateEffet) : null,
                    circonstances: props.params.effets.descEffet,
                },
                userdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            };
        } else {
            this.state = {
                activePage: 0,
                infoPage: false,
                emetteur: {},
                dispoMed: {},
                incident: {},
                userdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            };
        }
        

        this.handlerPage = this.handlerPage.bind(this);
        this.handlerEmetteur = this.handlerEmetteur.bind(this);
        this.handlerDispositifMedical = this.handlerDispositifMedical.bind(this);
        this.handlerIncident = this.handlerIncident.bind(this);

        this.onClickInfo = this.onClickInfo.bind(this);
        this.openAlert = this.openAlert.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.backHome = this.backHome.bind(this);
    }

    componentDidMount() {
        this.props.queryUser.refetch();
    }

    componentDidUpdate(pP, pS) {
        if (!this.state.loading) {
            this.setState({ loading: true, showAlert: false }, () => {neutralizeBack(this.openAlert)})
        }
        if (!pS.connected && this.props.queryUser.data !== undefined && this.props.queryUser.data[0] !== undefined) {
            this.setState({
                connected: true, 
                userdata: {
                    userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode,
                    dateFin:  this.props.queryUser.data[0].dateFin
            }});
        }
        if (!pS.closeConfirm && this.state.closeConfirm) {
            this.props.navigation('/');
        }
    }

    handlerPage(){
        let newPage = this.state.activePage + 1;
        this.setState({ activePage: newPage, showAlert: false })
    }
    handlerEmetteur(data){
        let newPage = this.state.activePage + 1;
        this.setState({ emetteur: data, activePage: newPage })
    }
    handlerDispositifMedical(data){
        let newPage = this.state.activePage + 1;
        this.setState({ dispoMed: data, activePage: newPage })
    }
    handlerIncident(data){
        let newPage = this.state.activePage + 1;
        this.props.mutationCreateDeclaration.mutate({ type: "Dispositif Médical", nomProduit: this.state.dispoMed.denominationComDM, effets: data.consequences });
        this.setState({ incident: data, activePage: newPage })
    }

    onClickInfo() { this.setState({ infoPage: !this.state.infoPage }) }

    
    openAlert() { this.setState({ showAlert: true }) }

    closeAlert() { this.setState({ showAlert: false }) }

    backHome() {
        this.setState({ showAlert: false, closeConfirm: true });
    }

    onClickReturn() {
        if (this.state.infoPage) this.setState({ infoPage: false })
        else if (this.state.activePage > 0) { 
            let newPage = this.state.activePage - 1;
            this.setState({ activePage: newPage })
        }
    }

    render() {
        const { activePage, showAlert } = this.state;

        return <ThemeProvider theme={Theme}>
            <Grid className='mainApp'>
                <header className='headerApp'>
                    <Grid className='headerDeclaration'>
                        {activePage === 0 || activePage === 4 ?
                            <VigicareLogoWhite />
                        : <div className='headerDeclarationLeft'>
                            <svg className="returnArrow" onClick={() => this.onClickReturn()} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <VigicareLogoWhite />
                        </div>
                        }
                        <div className='headerDeclarationCenter'>
                            <div className='headerText'><Trans>Déclaration d'effet indésirable</Trans></div>
                            <svg className="infoBtn" style={{ marginLeft: '0.25em'}} onClick={() => {this.onClickInfo()}} width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z" fill="#ffffff"/>
                            </svg>
                        </div>
                        {showAlert ?
                            <div onClick={() => this.closeAlert()}>
                                <HomeIcon color="white" />
                            </div> :
                            <div onClick={() => this.openAlert()}>
                                <HomeIcon color="white" />
                            </div>
                        }
                    </Grid>
                </header>
                <Grid className='mainDeclaration'>
                    { this.state.infoPage ? 
                            <Infos handler={this.onClickInfo}></Infos> 
                                : <>{ activePage === 0 ? <Pays handler={this.handlerPage} page={activePage * 25 + 25}></Pays>
                                    : <>{activePage === 1 ? <Emetteur data={this.state.emetteur} handler={this.handlerEmetteur} page={activePage * 25 + 25}></Emetteur> 
                                        : <>{activePage === 2 ? <DispositifMedical data={this.state.dispoMed} handler={this.handlerDispositifMedical} page={activePage * 25 + 25}></DispositifMedical> 
                                            : <> {activePage === 3 ? <Incident data={this.state.incident}  handler={this.handlerIncident} page={activePage * 25 + 25 }></Incident> 
                                                : <> {activePage === 4 ? <DeclarationValidePDF data={this.state}></DeclarationValidePDF> 
                                                    : <>
                                                </> }
                                            </> }                                       
                                        </>}
                                    </>}
                                </>
                            }</>
                        }
                    <Popup open={showAlert && activePage < 4} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b><Trans>Attention</Trans></b></p>
                            </div>
                                <p><Trans>En retournant à l'accueil, vous allez perdre les informations entrées dans ces formulaires</Trans></p>
                                <p><i><Trans>Vous pouvez revenir aux formulaires précédents avec la flèche présente dans le bandeau de navigation</Trans></i></p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.backHome()} variant="outlined" color="error"><Trans>Retour à l'accueil</Trans></Button>
                                <Button onClick={() => this.closeAlert()} variant="contained"><Trans>Je continue</Trans></Button>
                            </div>
                        </Grid>
                    </Popup>
                    <Popup open={showAlert && activePage === 4} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b><Trans>Attention</Trans></b></p>
                            </div>
                                <p>Etes vous surs d'avoir enregistré votre déclaration avant de revenir à l'accueil ?</p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.closeAlert()} variant="contained" color="error"><Trans>Non</Trans></Button>
                                <Button onClick={() => this.backHome()} variant="outlined"><Trans>Oui</Trans></Button>
                            </div>
                        </Grid>
                    </Popup>
                </Grid>
            </Grid>
        </ThemeProvider>
    }
}; 


export default withRouter(withQuery(withTranslation()(Dispositif)));

function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation(); 
        const navigate = useNavigate(); 
        if (location.state !== null) return <Child { ...props } params={location.state} navigation={navigate} />;
        else return <Child { ...props } navigation={navigate} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationCreateDeclaration = useMutation({
            mutationFn: (data) => {
                return newDeclaration(data).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });

        return <Child { ...props } mutationCreateDeclaration={mutationCreateDeclaration} queryUser={queryUser} />;
    }
}