import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from "react-i18next";

import { ThemeProvider, Button, Grid, TextField, MenuItem, Box  } from '@mui/material';
import { HomeRounded } from '@mui/icons-material';
import { Theme } from '../../styles/styles';
import { VigicareLogoWhite } from '../../styles/Icons';
import InfosProduits from './InfosProduit';

class DeclarationSelection extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            typeProduit: '',
            infoPage: false,
        };

        this.handleInfos = this.handleInfos.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleNav = this.handleNav.bind(this);
    }
    handleChangeDropdown(ev) {
        if (ev.target.value !== undefined) this.setState({ typeProduit: ev.target.value });
    }

    handleInfos() { window.scrollTo(0, 0); this.setState({ infoPage: !this.state.infoPage }); }

    handleNav() {
        switch (this.state.typeProduit) {
            case 'Médicament' : this.props.navigate("/declaration/medicament"); break;
            case 'Dispositif médical' : this.props.navigate("/declaration/dispositif"); break;
            case 'Cosmétique' : this.props.navigate("/declaration/cosmetique"); break;
            case 'Complément alimentaire' : this.props.navigate("/declaration/complement"); break;
            default: break;
        }
    }
    
    render() {
        const { typeProduit, infoPage } = this.state;
        return (<ThemeProvider theme={Theme}>
            {infoPage ? <InfosProduits handler={this.handleInfos}></InfosProduits> : 
                <Grid className="mainApp">
                    <header className='headerApp'>
                        <Grid className='headerDeclaration'>
                            <VigicareLogoWhite />
                            <p className='headerText'><Trans>Déclaration d'effet indésirable</Trans></p>
                            <div>
                                <Link to='/'>
                                    <HomeRounded color="white" />
                                </Link>
                            </div>
                        </Grid>
                    </header>
                    <div className='mainDeclaration'>
                        <div className='declarationInfos'>
                            <p><Trans>Choisissez le type de produit pour lequel réaliser la déclaration</Trans></p>
                            <svg className="infoImputabilite" onClick={() => {this.handleInfos()}} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8H11.01C11.5623 8 12.01 7.55228 12.01 7C12.01 6.44772 11.5623 6 11.01 6H11ZM12 11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V11Z" fill="#AFAFAF"/>
                            </svg>
                        </div>
                        <TextField fullWidth id="typeProduit" select value={typeProduit} onChange={this.handleChangeDropdown} label={<Trans>Type de produit *</Trans>}>
                            <MenuItem value="Médicament"><Trans>Médicament</Trans></MenuItem>
                            <MenuItem value="Dispositif médical"><Trans>Dispositif médical</Trans></MenuItem>
                            <MenuItem value="Cosmétique"><Trans>Cosmétique</Trans></MenuItem>
                            <MenuItem value="Complément alimentaire"><Trans>Complément alimentaire</Trans></MenuItem>
                        </TextField>
                        <p className="requiredMessage">
                            <Trans>* Champs obligatoires</Trans>
                        </p>
                        <Box className='bottomBtnForm'>
                            <Button variant="contained" color="blue" disabled={typeProduit === ''} onClick={this.handleNav}><Trans>Suivant</Trans></Button>
                        </Box>
                    </div>
                </Grid>}
        </ThemeProvider>
        )
    }
}

export default withRouter(DeclarationSelection);


function withRouter( Child ) {
    return ( props ) => {
        const navigate = useNavigate(); 
        return <Child { ...props } navigate={ navigate } />;
    }
};