import React from 'react';
import i18n from "i18next";
import { Link, useNavigate } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next"; 
import moment from 'moment';

import { ThemeProvider, Grid, Button, Checkbox, CircularProgress } from '@mui/material';

import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';

import { useMutation, useQuery } from '@tanstack/react-query';
import { newCheckoutMonthly, newCheckoutAnnual, getProductPrices } from '../../api/premium';
import { getUser } from '../../api/user';
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';

import './premium.css';
import { CheckPremium, WelcomeVisual, WelcomeVisualXL } from '../../styles/Icons';

import { config } from '../../config';

class Premium extends React.Component {
    constructor(props) {
        super(props);
        let userdata = {};
        let connected = false;
        if (props.queryUser.isSuccess && this.props.queryUser.data !== undefined && this.props.queryUser.data.length > 0) {
            userdata = {
                userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode, 
                    dateFin: this.props.queryUser.data[0].dateFin,
            };
            connected = true;
        };
        this.state = {
            activeOffer: 0,
            tabOpen: false,
            userdata,
            connected,
            acceptCGV: false,
            subscriptionDetails: {
                yearly: { price: null, currency: null },
                monthly: { price: null, currency: null }
            },
            loading: false
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this)
    }

    componentDidMount() {
        this.props.queryUser.refetch();
        this.props.mutationAbonnementPrice.mutate()
    }

    componentDidUpdate(pP, pS) {
        if (!pS.connected && this.props.queryUser.data !== undefined && this.props.queryUser.data[0] !== undefined) {
            if (moment(this.props.queryUser.data[0].dateFin).isAfter()) {
                this.props.navigation('/');
            } else {
                this.setState({
                    connected: true, 
                    userdata: {
                        userId: this.props.queryUser.data[0].userId,
                        pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                        proLabel: this.props.queryUser.data[0].proSanteLabel,
                        email: this.props.queryUser.data[0].email,
                        periode: this.props.queryUser.data[0].periode,
                        dateFin:  this.props.queryUser.data[0].dateFin
                }});
            }
            
        }
        if(this.props.mutationAbonnementPrice.isLoading && !this.state.loading){
            this.setState({loading: true})
        }
        if (this.props.mutationAbonnementPrice.isSuccess && !this.state.subscriptionDetails.yearly.price && !this.state.subscriptionDetails.monthly.price && !this.state.subscriptionDetails.yearly.currency && !this.state.subscriptionDetails.monthly.currency ) {
            const subscriptionPriceData = this.props.mutationAbonnementPrice.data;
            this.setState({
                loading: false,
                subscriptionDetails: {
                    yearly: {
                        price: subscriptionPriceData.yearly.price,
                        currency: subscriptionPriceData.yearly.currency
                    },
                    monthly: {
                        price: subscriptionPriceData.monthly.price,
                        currency: subscriptionPriceData.monthly.currency
                    }
                }
            });
        }
        if(this.props.mutationAbonnementPrice.isError && this.state.loading){
            this.setState({
                loading: false,
                subscriptionDetails: {
                    yearly: {
                        price: config.premium.yearly.price,
                        currency: config.premium.yearly.currency
                    },
                    monthly: {
                        price: config.premium.monthly.price,
                        currency: config.premium.monthly.currency
                    }
                }
            })
        }
        if (this.props.mutationAbonnementMensuel.isLoading && !this.props.mutationAbonnementAnnuel.isLoading && !this.state.tabOpen) {
            this.setState({ tabOpen: true })
        }
        if (this.props.mutationAbonnementMensuel.isSuccess && !this.props.mutationAbonnementAnnuel.isLoading && this.state.tabOpen) {
            window.open(this.props.mutationAbonnementMensuel.data);
            this.setState({ tabOpen: false })
        }
        if (this.props.mutationAbonnementAnnuel.isLoading && !this.props.mutationAbonnementMensuel.isLoading && !this.state.tabOpen) {
            this.setState({ tabOpen: true })
        }
        if (this.props.mutationAbonnementAnnuel.isSuccess && !this.props.mutationAbonnementMensuel.isLoading && this.state.tabOpen) {
            window.open(this.props.mutationAbonnementAnnuel.data);
            this.setState({ tabOpen: false })
        } 
    }

    handleSelect(i) {
        this.setState({ activeOffer: i,  tabOpen: false });
    }

    handleCheckout() {
        const { activeOffer } = this.state;
        if (activeOffer === 1) {
            this.props.mutationAbonnementMensuel.mutate();
        } else if (activeOffer === 2) {
            this.props.mutationAbonnementAnnuel.mutate();
        }
    }

    handleCheckBox() {
        this.setState({ acceptCGV: !this.state.acceptCGV  });
    }

    render () {
        const { t } = this.props;
        
        const { activeOffer, connected, acceptCGV, subscriptionDetails, loading } = this.state;
        
        const monthlyCurrency = getCurrencySymbol(subscriptionDetails.monthly.currency);
        const yearlyCurrency = getCurrencySymbol(subscriptionDetails.yearly.currency);

        return <ThemeProvider theme={Theme}>
            <Grid className="mainApp premiumApp">
                <Grid>
                    <Grid className='premiumTopRow'>
                        <Button disabled sx={{ color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important', fontSize: '10px' }}><AutoAwesomeRoundedIcon fontSize='12px' color='white' />{' Vigicare Premium'}</Button>
                        <Link to='/'><HomeIcon color='black' /></Link> 
                    </Grid>
                </Grid>
                <p className='premiumMainTitle'>{t("premium.title")}</p>
                <div className='premiumVisual'>
                    <WelcomeVisual />
                </div>
                <div className='premiumVisualXL'>
                    <WelcomeVisualXL />
                </div>
    
                <Grid className='premiumOffers'>
                    {config.premium.monthly.enabled && (
                    <fieldset className={`premiumScreenBox premiumScreenBoxMonth ${activeOffer === 1 ? 'selectedBox ' : ''} ${i18n.language === "en" ? "boxSmall" : ""}`.trimEnd()} onClick={() => this.handleSelect(1)}>
                        <p className='premiumTitle'>{t("premium.subscription.monthly.title")}</p>
                      {loading ?  <CircularProgress color="inherit" size={20} sx={{display:"block", margin:"auto"}} /> : (
                        <>
                        <p className='premiumText'><Trans i18nKey="premium:premium.subscription.monthly.price" values={{ month: subscriptionDetails.monthly.price, currency: monthlyCurrency }}><b>{`${subscriptionDetails.monthly.price}`}{{monthlyCurrency}}</b> per month</Trans></p>
                        <p className='premiumTextS'>{t("premium.subscription.monthly.info")}</p>
                        </> 
                       )}
                    </fieldset>
                    )}
                    {config.premium.yearly.enabled && (
                    <fieldset className={`premiumScreenBox ${activeOffer === 2 ? 'selectedBox' : ''} ${i18n.language === "en" ? "boxSmall" : ""}`.trimEnd()} onClick={() => this.handleSelect(2)}>
                        <legend className='premiumOffersBtn'><Button disabled sx={{ alignItems: 'flex-end', color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important', fontSize: '12px' }}>{t("premium.subscription.annual.tag")}</Button></legend>

                        <p className='premiumTitle'>{t("premium.subscription.annual.title")}</p>
                        {loading ? <CircularProgress color="inherit" size={20} sx={{display:"block", margin:"0.5rem auto"}} /> : (
                        <>
                        <p className='premiumText'><Trans i18nKey="premium:premium.subscription.annual.price" values={{ year: subscriptionDetails.yearly.price, currency: yearlyCurrency }}><b>{`${subscriptionDetails.yearly.price}`}{{yearlyCurrency}}</b> per year</Trans></p>
                        <p className='premiumTextS'>{t("premium.subscription.annual.info")}</p>
                        </>
                        )}
                    </fieldset>
                    )}
                </Grid>
                <div className='checkboxSignIn' onClick={this.handleCheckBox}>
                    <Checkbox checked={acceptCGV}  id="acceptCGV"/>
                    <p id="acceptCGV"><Trans i18nKey="premium:premium.termAndCondition">I have read and accept the <a href='/cgu' target='_blank'>Terms and Conditions</a> of Vigicare.*</Trans></p>
                </div>

                <Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p>{t("premium.service.use")}</p>
                    </Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p>{t("premium.service.test")}</p></Grid>
                    <Grid className='premiumRow'>
                        <div>
                            <CheckPremium className='iconPremium' />
                        </div>
                        <p>{t("premium.service.search")}</p></Grid>
                </Grid>
               
                {!connected ?
                    <div className='premiumConnectionBox'>
                        <p className='premiumTitle'>{t("premium.loginMessage")}</p>
                        <div className='premiumLinkButtons'>
                            <Link to ="/inscription" className='linkButton'><Button variant="outlined" color="premium">{t("premium.action.signup")}</Button></Link>
                            <Link to ="/auth" className='linkButton'><Button variant="contained" color="premium">{t("premium.action.login")}</Button></Link>
                        </div>
                    </div>
                    : <>
                    {activeOffer === 0 || !acceptCGV || loading?
                        <div className='bottomBtnFullWidth'>
                            <Button disabled>{t("premium.action.subscribe")}</Button>
                        </div>
                    : <div className='bottomBtnFullWidth'>
                        <Button color='white' sx={{ background: '#F46B45 !important' }} onClick={this.handleCheckout}>{t("premium.action.subscribe")}</Button>
                    </div>}
                    </>
                }
                
            </Grid>
        </ThemeProvider>
    }
}

export default withRouter(withQuery(withTranslation("premium")(Premium)));

function withRouter( Child ) {
    return ( props ) => {
        const navig = useNavigate();
        return <Child { ...props } navigation={navig} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationAbonnementMensuel = useMutation({
            mutationFn: () => {
               return newCheckoutMonthly().then((r) => {return r.data}).catch(e =>  {throw e})
            },
        });
        const mutationAbonnementAnnuel = useMutation({
            mutationFn: () => {
               return newCheckoutAnnual().then((r) => {return r.data}).catch(e =>  {throw e})
            },
        });
        const mutationAbonnementPrice = useMutation({
            mutationFn: () => {
                return getProductPrices().then((r) => {return r.data}).catch(e => {throw e})
            }
        })
        return <Child { ...props } mutationAbonnementMensuel={mutationAbonnementMensuel} mutationAbonnementAnnuel={mutationAbonnementAnnuel} queryUser={queryUser}  mutationAbonnementPrice={mutationAbonnementPrice} />;
    }
}
