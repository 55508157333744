import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider, Grid, Button } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getUser,
  logoutUser,
  updatePassword,
  updateUser,
  updateUserAccount
} from "../../api/user";
import { getAllDeclarations, getAllImputabilityTests } from "../../api/history";
import {
  deleteSubscription,
  newCheckoutAnnual,
  newCheckoutMonthly,
  reviveSubscription,
} from "../../api/premium";
import PremiumSuccess from "../premium/PremiumSuccess";

import LanguagePopup from "./pages/Language";
import HomePage from "./pages/Home";
import Profile from "./pages/Profile";
import ProductSearch from "./pages/ProductSearch";

import {
  FrenchFlag,
  EnglishFlag,
  VigicareLogo,
  SpanishFlag,
  DeutschFlag,
  ItalianFlag,
  VigicareLogoWhite,
} from "../../styles/Icons";
import { Theme } from "../../styles/styles";
import "./index.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    let activePage = 0;
    let displayPremium = false;
    if (props.page !== undefined) {
      activePage = props.page;
    }
    if (props.premium !== undefined && props.premium === true)
      displayPremium = true;
    if (props.queryUser.data !== undefined && props.queryUser.data.length > 0) {
      this.state = {
        activePage,
        connected: true,
        displayLanguage: false,
        msg: null,
        msgPW: null,
        refresh: false,
        tabOpen: false,
        isUnsubscribeLoading: false,
        isReviveSubscriptionLoading: false,
        displayPremium,
        userdata: {
          userId: props.queryUser.data[0].userId,
          pro: props.queryUser.data[0].proSante === "true" ? true : false,
          proLabel: props.queryUser.data[0].proSanteLabel,
          email: props.queryUser.data[0].email,
          name: props.queryUser.data[0].name,
          periode: props.queryUser.data[0].periode,
          dateFin: props.queryUser.data[0].dateFin,
          cancelAt: props.queryUser.data[0].cancelAt,
        },
        imputabilityList:
          props.queryImputabilites.data !== undefined &&
          props.queryImputabilites.data.length !== undefined
            ? props.queryImputabilites.data
            : [],
        declarationList:
          props.queryDeclarations.data !== undefined &&
          props.queryDeclarations.data.length !== undefined
            ? props.queryDeclarations.data
            : [],
      };
    } else {
      this.state = {
        activePage,
        connected: false,
        displayLanguage: false,
        msg: null,
        msgPW: null,
        refresh: false,
        tabOpen: false,
        isUnsubscribeLoading: false,
        isReviveSubscriptionLoading: false,
        displayPremium,
        userdata: {},
        imputabilityList: [],
        declarationList: [],
      };
    }

    this.handlerPage = this.handlerPage.bind(this);
    this.handlerDisplay = this.handlerDisplay.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handlerCheckoutMonthly = this.handlerCheckoutMonthly.bind(this);
    this.handlerCheckoutAnnual = this.handlerCheckoutAnnual.bind(this);
    this.handlerUnsubscribe = this.handlerUnsubscribe.bind(this);
    this.handlerReviveSubscription = this.handlerReviveSubscription.bind(this);
    this.handlerSubscribed = this.handlerSubscribed.bind(this);
  }

  componentDidMount() {
    this.props.queryUser.refetch();
    this.props.queryImputabilites.refetch();
    this.props.queryDeclarations.refetch();
  }

  componentDidUpdate(pP, pS) {
    if (
      this.props.page !== pP.page ||
      this.state.activePage !== this.props.page
    ) {
      this.setState({ activePage: this.props.page });
    }
    if (this.props.mutationUpdateUser.isLoading && this.state.msg !== null) {
      this.setState({ msg: null });
    }
    if (
      this.props.mutationUpdatePassword.isLoading &&
      this.state.msgPW !== null
    ) {
      this.setState({ msgPW: null });
    }
    if (
      !pS.connected &&
      this.props.queryUser.data !== undefined &&
      this.props.queryUser.data[0] !== undefined &&
      !this.props.mutationLogout.isSuccess &&
      this.state.activePage === 0
    ) {
      this.setState({
        connected: true,
        userdata: {
          userId: this.props.queryUser.data[0].userId,
          pro: this.props.queryUser.data[0].proSante === "true" ? true : false,
          proLabel: this.props.queryUser.data[0].proSanteLabel,
          name: this.props.queryUser.data[0].name,
          email: this.props.queryUser.data[0].email,
          periode: this.props.queryUser.data[0].periode,
          dateFin: this.props.queryUser.data[0].dateFin,
          cancelAt: this.props.queryUser.data[0].cancelAt,
        },
      });
    }
    if (
      this.props.queryUser.isSuccess &&
      this.props.queryUser.data[0] !== undefined &&
      (this.props.queryUser.data[0].email !== this.state.userdata.email ||
        this.props.queryUser.data[0].dateFin !== this.state.userdata.dateFin)
    ) {
      this.setState({
        connected: true,
        userdata: {
          userId: this.props.queryUser.data[0].userId,
          pro: this.props.queryUser.data[0].proSante === "true" ? true : false,
          proLabel: this.props.queryUser.data[0].proSanteLabel,
          name: this.props.queryUser.data[0].name,
          email: this.props.queryUser.data[0].email,
          periode: this.props.queryUser.data[0].periode,
          dateFin: this.props.queryUser.data[0].dateFin,
          cancelAt: this.props.queryUser.data[0].cancelAt,
        },
      });
    }
    if (
      this.state.connected &&
      this.props.mutationLogout.isSuccess &&
      this.state.activePage === 2
    ) {
      this.setState({
        activePage: 0,
        connected: false,
        displayLanguage: false,
        msg: null,
        msgPW: null,
        refresh: false,
        tabOpen: false,
        userdata: {},
        imputabilityList: [],
        declarationList: [],
      });
    }
    if (pS.connected && !this.state.connected) this.props.navigation("/");
    if (!this.state.displayPremium && pS.displayPremium) {
      this.props.queryUser.refetch();
      this.props.queryImputabilites.refetch();
      this.props.queryDeclarations.refetch();
    }

    if (
      this.props.mutationUpdateUser.isSuccess &&
      this.props.queryUser.data !== undefined &&
      this.props.queryUser.data[0] !== undefined &&
      (this.props.queryUser.data[0].email !== this.state.userdata.email ||
        this.props.queryUser.data[0].proSanteLabel !==
          this.state.userdata.proLabel)
    ) {
      this.setState({
        msg: "Données mises à jour",
        userdata: {
          ...this.state.userdata,
          email: this.props.queryUser.data[0].email,
          proLabel: this.props.queryUser.data[0].proSanteLabel,
        },
      });
    }
    if (
      this.props.mutationUpdateUser.isError &&
      this.state.msg !== "Erreur dans la mise à jour des données"
    ) {
      this.setState({ msg: "Erreur dans la mise à jour des données" });
    }
    if (
      this.props.mutationUpdatePassword.isError &&
      this.state.msgPW !== "Erreur de changement de mot de passe"
    ) {
      this.setState({ msgPW: "Erreur de changement de mot de passe" });
    }
    if (
      this.props.mutationUpdatePassword.isSuccess &&
      this.state.msgPW !== "Changement de mot de passe validé"
    ) {
      this.setState({ msgPW: "Changement de mot de passe validé" });
    }
    if (
      this.props.queryUser.isSuccess &&
      this.props.queryUser.data[0] !== undefined && this.state.userdata.pro !== this.props.queryUser.data[0].proSante && this.state.userdata.proLabel !== this.props.queryUser.data[0].proSanteLabel
    ) {
      this.setState({
        userdata: {
          ...this.state.userdata,
          pro: this.props.queryUser.data[0].proSante === "true" ? true : false,
          proLabel: this.props.queryUser.data[0].proSanteLabel
        }
      })
    }
    if (
      this.props.queryImputabilites.isSuccess &&
      this.state.imputabilityList !== this.props.queryImputabilites.data
    ) {
      this.setState({ imputabilityList: this.props.queryImputabilites.data });
    }
    if (
      this.props.queryDeclarations.isSuccess &&
      this.state.declarationList !== this.props.queryDeclarations.data
    ) {
      this.setState({ declarationList: this.props.queryDeclarations.data });
    }

    if (
      this.props.mutationAbonnementMensuel.isLoading &&
      !this.props.mutationAbonnementAnnuel.isLoading &&
      !this.state.tabOpen
    ) {
      this.setState({ tabOpen: true });
    }
    if (
      this.props.mutationAbonnementMensuel.isSuccess &&
      !this.props.mutationAbonnementAnnuel.isLoading &&
      this.state.tabOpen
    ) {
      window.open(this.props.mutationAbonnementMensuel.data);
      this.setState({ tabOpen: false });
    }
    if (
      this.props.mutationAbonnementAnnuel.isLoading &&
      !this.props.mutationAbonnementMensuel.isLoading &&
      !this.state.tabOpen
    ) {
      this.setState({ tabOpen: true });
    }
    if (
      this.props.mutationAbonnementAnnuel.isSuccess &&
      !this.props.mutationAbonnementMensuel.isLoading &&
      this.state.tabOpen
    ) {
      window.open(this.props.mutationAbonnementAnnuel.data);
      this.setState({ tabOpen: false });
    }
    if (
      this.props.mutationResiliationAbonnement.isLoading &&
      !this.state.isUnsubscribeLoading
    ) {
      this.setState({ isUnsubscribeLoading: true});
    }
    if (
      this.props.mutationResiliationAbonnement.isSuccess &&
      this.state.isUnsubscribeLoading
    ) {
      this.setState({ isUnsubscribeLoading: false });
    }
    if(
      this.props.queryUser.isSuccess &&
      this.props.queryUser.data[0] !== undefined && this.state.userdata.cancelAt !== this.props.queryUser.data[0].cancelAt
    ) {
      this.setState({
        userdata: {
          ...this.state.userdata,
         cancelAt: this.props.queryUser.data[0].cancelAt
        }
      })
    }
    if(this.props.mutationReviveAbonnement.isLoading && !this.state.isReviveSubscriptionLoading){
      this.setState({isReviveSubscriptionLoading: true})
    }
    if(this.props.mutationReviveAbonnement.isSuccess && this.state.isReviveSubscriptionLoading){
      this.setState({isReviveSubscriptionLoading: false})
    }
  }

  handlerCheckoutMonthly() {
    this.props.mutationAbonnementMensuel.mutate();
  }
  handlerCheckoutAnnual() {
    this.props.mutationAbonnementAnnuel.mutate();
  }

  handlerSubscribed() {
    this.props.queryUser.refetch();
    this.props.queryImputabilites.refetch();
    this.props.queryDeclarations.refetch();
  }

  handlerUnsubscribe() {
    this.props.mutationResiliationAbonnement.mutate();
  }

  handlerReviveSubscription() {
   this.props.mutationReviveAbonnement.mutate();
  }

  displayFlag(lng) {
    switch (lng) {
      case "fr":
        return <FrenchFlag />;
      case "en":
        return <EnglishFlag />;
      case "sp":
        return <SpanishFlag />;
      case "de":
        return <DeutschFlag />;
      case "it":
        return <ItalianFlag />;
      default:
        return <FrenchFlag />;
    }
  }

  handlerDisplay() {
    this.setState({ displayLanguage: !this.state.displayLanguage });
  }

  closePopup() {
    this.setState({ displayLanguage: false });
  }

  handlerPage(page) {
    if (page === this.state.activePage && this.props.premium === undefined) {
      this.props.navigation(0);
    } else {
      if (page === 0) this.props.navigation("/");
      else if (page === 1) this.props.navigation("/recherche");
      else if (page === 2) this.props.navigation("/profil");
    }
  }

  handleLogOut() {
    this.props.mutationLogout.mutate();
  }

  handleUpdateUser(data) {

    let reqData = {};
    if (data.email !== null && !data.email.match(/^\S+@\S+\.\S+$/)) {
      this.setState({ msg: "Format d'email incorrect" });
    } else if (this.state.userdata.pro) {
      if (
        data.email === null ||
        data.email === "" ||
        data.profession === null ||
        data.profession === "" ||
        (data.profession === "Autre" && data.professionAutre === "")
      ) {
        this.setState({ msg: "Champ(s) vide(s)" });
      } else {
        if (
          data.email !== this.state.userdata.email &&
          data.email !== null &&
          data.email !== ""
        ) {
          reqData.email = data.email;
        }
        if (
          data.profession === "Autre" &&
          data.professionAutre !== null &&
          data.professionAutre !== ""
        ) {
          reqData.proSanteLabel = data.professionAutre;
        } else {
          reqData.proSanteLabel = data.profession;
        }
        this.props.mutationUpdateUser.mutate(reqData);
      }
    } else {
      if (data.email !== null && data.email !== "") {
        reqData.email = data.email;
        this.props.mutationUpdateUser.mutate(reqData);
      } else {
        this.setState({ msg: "Champ manquant" });
      }
    }
  }

  handleUpdatePassword(data) {
    let reqData = { ...data };
    reqData.email = this.state.userdata.email;
    this.props.mutationUpdatePassword.mutate(reqData);
  }

  handleUpdateAccount(data) {
    let reqData = {}
    reqData.proSanteLabel = data.profession;
    if (
      data.profession === "Autre" &&
      data.professionAutre !== null &&
      data.professionAutre !== ""
    ) {
      reqData.proSanteLabel = data.professionAutre;
    }
    this.props.mutationUpdateAccount.mutate(reqData);
  }

  render() {
    const {
      activePage,
      connected,
      displayLanguage,
      displayPremium,
      isReviveSubscriptionLoading,
      isUnsubscribeLoading,
      msg,
      msgPW,
      userdata,
    } = this.state;
    
    const { t } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <Grid className="mainApp">
          <header className="headerApp">
            <div className="headerHome">
              <div onClick={() => this.handlerPage(0)}>
                <VigicareLogoWhite />
                <VigicareLogo />
              </div>
              <div>
                <Button
                  onClick={() => this.handlerPage(0)}
                  className="headerHomeMenu"
                  sx={
                    activePage === 0
                      ? { textDecorationLine: "underline !important" }
                      : {}
                  }
                >
                  {t("app:common.home")}
                </Button>
                {userdata.pro ? (
                  <Button
                    onClick={() => this.handlerPage(1)}
                    className="headerHomeMenu"
                    sx={
                      activePage === 1
                        ? { textDecorationLine: "underline !important" }
                        : {}
                    }
                  >
                    {t("app:common.research")}
                  </Button>
                ) : null}

                {connected ? (
                  <Button
                    onClick={() => this.handlerPage(2)}
                    className="headerHomeMenu"
                    variant="contained"
                    color="white"
                    sx={
                      activePage === 2
                        ? {
                            textDecorationLine: "underline !important",
                            color: "#239497 !important",
                          }
                        : { color: "#239497 !important" }
                    }
                  >
                    {t("app:common.myAccount")}
                  </Button>
                ) : (
                  <Link to="/connexion" className="linkButton">
                    <Button
                      sx={{
                        textDecorationLine: "none !important",
                        color: "#239497 !important",
                      }}
                      className="headerHomeMenu"
                      color="white"
                      variant="contained"
                    >
                      {t("app:common.login")}
                    </Button>
                  </Link>
                )}
                <Button
                  size="small"
                  variant="contained"
                  color="white"
                  onClick={this.handlerDisplay}
                  sx={{
                    borderRadius: "0.5em",
                    marginLeft: "1em",
                    paddingTop: "4px !important",
                    paddingBottom: "4px !important",
                  }}
                >
                  {this.displayFlag(i18n.language)}
                </Button>
              </div>
            </div>
          </header>
          <div className="mainHome">
            {activePage === 0 ? (
              <HomePage
                data={this.state}
                handler={this.handlerPage}
                handlerSub={this.handlerSubscribed}
                displayPremium={displayPremium}
              ></HomePage>
            ) : (
              <>
                {activePage === 1 ? (
                  <ProductSearch data={this.state}></ProductSearch>
                ) : (
                  <>
                    {activePage === 2 && Object.keys(userdata).length ? (
                        <Profile
                          data={this.state}
                          handlerDeconnexion={this.handleLogOut}
                          handlerUpdateUser={this.handleUpdateUser}
                          handlerUpdatePassword={this.handleUpdatePassword}
                          handlerUpdateAccount={this.handleUpdateAccount}
                          handlerCheckoutMonthly={this.handlerCheckoutMonthly}
                          handlerCheckoutAnnual={this.handlerCheckoutAnnual}
                          handlerUnsubscribe={this.handlerUnsubscribe}
                          handlerReviveSubscription={this.handlerReviveSubscription}
                          msg={msg}
                          msgPW={msgPW}
                          isReviveSubscriptionLoading={isReviveSubscriptionLoading}
                          isUnsubscribeLoading={isUnsubscribeLoading}
                        ></Profile>
                    ) : null}
                  </>
                )}
              </>
            )}
            {displayLanguage === true ? (
              <Popup
                open={displayLanguage}
                position="center"
                onClose={() => this.closePopup()}
                contentStyle={{ minWidth: "60%" }}
              >
                <LanguagePopup handler={this.handlerDisplay} />
              </Popup>
            ) : (
              <></>
            )}
            {displayPremium === true ? (
              <Popup
                open={displayPremium}
                position="center"
                onClose={() => this.setState({ displayPremium: false })}
                contentStyle={{
                  minWidth: "60%",
                  minHeight: "40%",
                  borderRadius: "1em",
                }}
              >
                <PremiumSuccess
                  handler={() => this.setState({ displayPremium: false })}
                />
              </Popup>
            ) : (
              <></>
            )}
          </div>

          <footer className="mainFooter">
            <div className="footerCol" onClick={() => this.handlerPage(0)}>
              <HomeOutlinedIcon color={activePage === 0 ? "blue" : "grey"} />
              <div
                className={
                  activePage === 0 ? "mainFooterTextBlue" : "mainFooterText"
                }
              >
                {t("app:common.home")}
              </div>
            </div>
            {userdata.pro ? (
              <div className="footerCol" onClick={() => this.handlerPage(1)}>
                <SearchIcon color={activePage === 1 ? "blue" : "grey"} />
                <div
                  className={
                    activePage === 1 ? "mainFooterTextBlue" : "mainFooterText"
                  }
                >
                  {t("app:common.research")}
                </div>
              </div>
            ) : null}

            {connected ? (
              <div className="footerCol" onClick={() => this.handlerPage(2)}>
                <PersonOutlineOutlinedIcon
                  color={activePage === 2 ? "blue" : "grey"}
                />
                <div
                  className={
                    activePage === 2 ? "mainFooterTextBlue" : "mainFooterText"
                  }
                >
                  {t("app:common.profile")}
                </div>
              </div>
            ) : (
              <div className="footerCol">
                <Link to="/connexion">
                  <PersonOutlineOutlinedIcon color="grey" />
                </Link>
                <Link to="/connexion" className="linkFooter">
                  <div className="mainFooterText">
                    {t("app:common.profile")}
                  </div>
                </Link>
              </div>
            )}
          </footer>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(withQuery(withTranslation("home")(Home)));

function withRouter(Child) {
  return (props) => {
    const navig = useNavigate();
    return <Child {...props} navigation={navig} />;
  };
}

function withQuery(Child) {
  return (props) => {
    const queryClient = useQueryClient();

    const queryUser = useQuery(["fetch-user"], () =>
      getUser()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        }),
    );
    const queryImputabilites = useQuery(["fetch-imputability"], () =>
      getAllImputabilityTests()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        }),
    );
    const queryDeclarations = useQuery(["fetch-declaration"], () =>
      getAllDeclarations()
        .then((r) => {
          return r.data;
        })
        .catch((e) => {
          throw e;
        }),
    );

    const mutationAbonnementMensuel = useMutation({
      mutationFn: () => {
        return newCheckoutMonthly()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationAbonnementAnnuel = useMutation({
      mutationFn: () => {
        return newCheckoutAnnual()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });

    const mutationResiliationAbonnement = useMutation({
      mutationFn: () => {
        return deleteSubscription()
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
      onSuccess: () => {
        queryUser.refetch();
      },
    });

    const mutationLogout = useMutation({
      mutationFn: () => {
        return logoutUser()
          .then((r) => {
            queryClient.getQueryCache().clear();
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationUpdateUser = useMutation({
      mutationFn: (data) => {
        return updateUser(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
      onSuccess: () => {
        queryClient.getQueryCache().clear();
      },
    });
    const mutationUpdatePassword = useMutation({
      mutationFn: (data) => {
        return updatePassword(data)
          .then((r) => {
            return r.data;
          })
          .catch((e) => {
            throw e;
          });
      },
    });
    const mutationUpdateAccount = useMutation({
      mutationFn: (data) => {
        return updateUserAccount(data)
            .then((r) => {
              return r.data;
            })
            .catch((e) => {
              throw e;
            })
      },
      onSuccess: () => {
        queryUser.refetch();
      },
    })
    const mutationReviveAbonnement = useMutation({
      mutationFn: () => {
        return reviveSubscription()
        .then((r)=> {
          return r.data;
        })
        .catch((e)=>{
          throw e
        })
      },
      onSuccess: () => {
        queryUser.refetch();
      }
    })
    return (
      <Child
        {...props}
        mutationAbonnementMensuel={mutationAbonnementMensuel}
        mutationAbonnementAnnuel={mutationAbonnementAnnuel}
        mutationResiliationAbonnement={mutationResiliationAbonnement}
        queryUser={queryUser}
        queryImputabilites={queryImputabilites}
        queryDeclarations={queryDeclarations}
        mutationUpdateUser={mutationUpdateUser}
        mutationLogout={mutationLogout}
        mutationUpdatePassword={mutationUpdatePassword}
        mutationUpdateAccount={mutationUpdateAccount}
        mutationReviveAbonnement={mutationReviveAbonnement}
      />
    );
  };
}
