import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Button } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { withTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import NavigateNext from "@mui/icons-material/NavigateNext";

import {
  ScienceIcon,
  WelcomeVisual,
  WelcomeVisualXL,
  FilesIcon,
  MedicalIcon,
  VigicareLogoWhiteXL,
  VigicareLogoBlack,
} from "../../../styles/Icons";
import HistoryTest from "../../sharedComponents/HistoryTest";
import { neutralizeBack, revivalBack } from "../../../misc/navigation";
import { config } from "../../../config";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    if (props.data !== undefined && Object.keys(props.data).length !== 0) {
      this.state = {
        data: {
          connected: props.data.connected,
          email: props.data.userdata.email,
          username: props.data.userdata.name,
          historyImp: props.data.imputabilityList,
          historyDecla: props.data.declarationList,
        },
        displayProd: false,
        product: {},
      };
    } else {
      this.state = {
        data: {
          connected: false,
          email: "",
          username: "",
          historyImp: [],
          historyDecla: [],
        },
        displayProd: false,
        product: {},
      };
    }

    this.showTest = this.showTest.bind(this);
    this.hideTest = this.hideTest.bind(this);

    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.data.historyImp !== this.props.data.imputabilityList) {
      this.setState({
        data: {
          ...this.state.data,
          historyImp: this.props.data.imputabilityList,
        },
      });
    }
    if (this.state.data.historyDecla !== this.props.data.declarationList) {
      this.setState({
        data: {
          ...this.state.data,
          historyDecla: this.props.data.declarationList,
        },
      });
    }
  }

  componentDidUpdate() {
    if (this.state.data.historyImp !== this.props.data.imputabilityList) {
      this.setState({
        data: {
          ...this.state.data,
          historyImp: this.props.data.imputabilityList,
        },
      });
    }
    if (this.state.data.historyDecla !== this.props.data.declarationList) {
      this.setState({
        data: {
          ...this.state.data,
          historyDecla: this.props.data.declarationList,
        },
      });
    }
    if (this.props.data.userdata.email !== this.state.data.email) {
      this.setState({
        data: {
          ...this.state.data,
          email: this.props.data.userdata.email,
          username:
            this.props.data.userdata.name !== undefined &&
            this.props.data.userdata.name !== ""
              ? this.props.data.userdata.name
              : this.props.data.userdata.name !== undefined
              ? this.props.data.userdata.email.match(/^([^@]*)@/)[1]
              : "",
        },
      });
    }
  }

  showTest(prod) {
    if (!this.state.displayProd)
      this.setState(
        { displayProd: !this.state.displayProd, product: prod },
        () => neutralizeBack(this.hideTest),
      );
  }

  hideTest() {
    this.setState({ displayProd: false, product: null }, revivalBack);
  }

  render() {
    const { data, displayProd, product } = this.state;
    const connected = this.props.data.connected;
    const pro = this.props.data.userdata.pro;
    const premium =
      this.props.data.userdata.dateFin !== undefined &&
      this.props.data.userdata.dateFin !== null
        ? moment(this.props.data.userdata.dateFin).isSameOrAfter()
        : false;
    moment.locale(i18n.language);

    const { t } = this.props;

    return (
      <div>
        {displayProd && product !== null ? (
          <HistoryTest
            data={product}
            handler={this.hideTest}
            premium={premium}
            connected={connected}
          />
        ) : (
          <>
            <div className="accueilDisplay">
              <div className="accueilDisplayCol">
                <div className="accueilDisplayLogo">
                  <VigicareLogoWhiteXL />
                  <VigicareLogoBlack />
                </div>
                {!connected ? (
                  <>
                    <Grid className="homeMainTitle homeMainTitleDisplay">
                      <div className="homeMainVisual">
                        <WelcomeVisual />
                      </div>
                      <div className="homeMainVisualXL">
                        <WelcomeVisualXL />
                      </div>
                      <p>{t("home.title")}</p>
                    </Grid>
                    <Grid>
                      <p>
                        <Trans i18nKey="home:home.message">
                          <b>Tiredness, spots, headaches, pain?</b>
                          <b>Vigicare is here to help you!</b>
                        </Trans>
                      </p>
                    </Grid>

                    <Grid className="homePro">
                      <MedicalIcon />
                      <div>
                        <h6>{t("home.proMessage.title")}</h6>
                        <p>
                          <Link to="/pro">{t("home.proMessage.link")}</Link>{" "}
                          {t("home.proMessage.message")}
                        </p>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <Grid className="homeMainTitle homeMainTitleDisplay">
                    <div className="homeMainVisual">
                      <WelcomeVisual />
                    </div>
                    <div className="homeMainVisualXL">
                      <WelcomeVisualXL />
                    </div>
                    <p>{t("home.welcome", { user: data.username })}</p>

                    {premium ? (
                      <Box
                        color="#F46B45"
                        sx={{
                          fontSize: "14px",
                          padding: "0.5em",
                          fontWeight: "600",
                        }}
                      >
                        {t("app:common.premium")}
                      </Box>
                    ) : (
                      <></>
                    )}
                    {pro ? (
                      <Box
                        color="#239497"
                        sx={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {t("app:common.pro")}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Grid>
                )}
              </div>

              <div className="accueilDisplayCol">
                <Grid className="accueilMainBox blueBox">
                  <Grid className="homeRow">
                    <div className="homeIcon" color="whiteBlue">
                      <ScienceIcon />
                    </div>
                    <p className="homeTitle">{t("home.adverseEffect.title")}</p>
                  </Grid>

                  <p className="homeText">{t("home.adverseEffect.message")}</p>
                  <Link to="/imputabilite" className="linkButton">
                    <Grid className="homeLink" bgcolor="#239497">
                      <p>{t("home.adverseEffect.label")}</p>
                      <NavigateNextIcon color="white" />
                    </Grid>
                  </Link>
                </Grid>

                {pro ? (
                  <>
                    {premium ? (
                      <Grid className="accueilMainBox orangeBox">
                        <Grid className="homeRowSub">
                          <Grid className="homeRow">
                            <div className="homeIconOrange" color="whiteOrange">
                              <SearchIcon color="premium" />
                            </div>
                            <p className="homeTitle">
                              {t("home.productSearch.title")}
                            </p>
                          </Grid>
                          <Button
                            disabled
                            sx={{
                              marginLeft: "0.5em",
                              color: "white !important",
                              background:
                                "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                              textTransform: "unset !important",
                              fontSize: "10px",
                            }}
                          >
                            <AutoAwesomeRoundedIcon
                              fontSize="12px"
                              color="white"
                            />
                            {t("app:common.vigicarePremium")}
                          </Button>
                        </Grid>

                        <p className="homeText">
                          {t("home.productSearch.messagePro1")}
                          <br />
                          {t("home.productSearch.messagePro2")}
                        </p>
                        <Grid
                          className="homeLink"
                          bgcolor="#F56E46"
                          onClick={() => this.props.handler(1)}
                        >
                          <p>{t("home.productSearch.label")}</p>
                          <NavigateNextIcon color="white" />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid className="accueilMainBox greyBox">
                        <Grid className="homeRowSub">
                          <Grid className="homeRow">
                            <div className="homeIconGrey" color="whiteGrey">
                              <SearchIcon color="grey" />
                            </div>
                            <p className="homeTitle">
                              {t("home.productSearch.title")}
                            </p>
                          </Grid>
                          <div>
                            <Link to="/premium" className="linkButton">
                              <Button
                                sx={{
                                  marginLeft: "0.5em",
                                  color: "white !important",
                                  background:
                                    "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                                  textTransform: "unset !important",
                                  fontSize: "10px",
                                }}
                              >
                                <LockIcon fontSize="small" color="white" />
                                {t("app:common.vigicarePremium")}
                              </Button>
                            </Link>
                          </div>
                        </Grid>

                        <p className="homeText">
                          {t("home.productSearch.messagePro1")}
                          <br />
                          {t("home.productSearch.messagePro2")}
                        </p>

                        <Grid
                          className="homeLink"
                          bgcolor="grey"
                          onClick={() => this.props.handler(1)}
                        >
                          <p>{t("home.productSearch.label")}</p>
                          <NavigateNextIcon color="white" />
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {premium ? (
                      <Grid className="accueilMainBox orangeBox">
                        <Grid className="homeRowSub">
                          <Grid className="homeRow">
                            <div className="homeIconOrange" color="whiteOrange">
                              <SearchIcon color="premium" />
                            </div>
                            <p className="homeTitle">
                              {t("home.productSearch.title")}
                            </p>
                          </Grid>
                          <Button
                            disabled
                            sx={{
                              marginLeft: "0.5em",
                              color: "white !important",
                              background:
                                "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                              textTransform: "unset !important",
                              fontSize: "10px",
                            }}
                          >
                            <AutoAwesomeRoundedIcon
                              fontSize="12px"
                              color="white"
                            />
                            {t("app:common.vigicarePremium")}
                          </Button>
                        </Grid>

                        <p className="homeText">
                          {t("home.productSearch.message")}
                        </p>
                        <Grid
                          className="homeLink"
                          bgcolor="#F56E46"
                          onClick={() => this.props.handler(1)}
                        >
                          <p>{t("home.productSearch.label")}</p>
                          <NavigateNextIcon color="white" />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid className="accueilMainBox greyBox">
                        <Grid className="homeRowSub">
                          <Grid className="homeRow">
                            <div className="homeIconGrey" color="whiteGrey">
                              <SearchIcon color="grey" />
                            </div>
                            <p className="homeTitle">
                              {t("home.productSearch.title")}
                            </p>
                          </Grid>
                          <div>
                            <Link to="/premium" className="linkButton">
                              <Button
                                sx={{
                                  marginLeft: "0.5em",
                                  color: "white !important",
                                  background:
                                    "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
                                  textTransform: "unset !important",
                                  fontSize: "10px",
                                }}
                              >
                                <LockIcon fontSize="small" color="white" />
                                {t("app:common.vigicarePremium")}
                              </Button>
                            </Link>
                          </div>
                        </Grid>

                        <p className="homeText">
                          {t("home.productSearch.message")}
                        </p>
                        <Grid
                          className="homeLink"
                          bgcolor="grey"
                          onClick={() => this.props.handler(1)}
                        >
                          <p>{t("home.productSearch.label")}</p>
                          <NavigateNextIcon color="white" />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

                <Grid className="accueilMainBox blueBox">
                  <Grid className="homeRow">
                    <div className="homeIconFiles" color="whiteBlue">
                      <FilesIcon />
                    </div>
                    <p className="homeTitle">{t("home.declaration.title")}</p>
                  </Grid>

                  <p className="homeText">{t("home.declaration.message")}</p>
                  <Link to="/declaration" className="linkButton">
                    <Grid className="homeLink" bgcolor="#239497">
                      <p>{t("home.declaration.label")}</p>
                      <NavigateNextIcon color="white" />
                    </Grid>
                  </Link>
                </Grid>
              </div>
            </div>
            <hr />
            <div className="homeHistory">
              <p className="homeSubtitle">{t("home.history.title")}</p>
              <Grid className="accueilDisplay">
                {connected ? (
                  <Grid className="accueilDisplayCol">
                    {data.historyImp.length !== undefined &&
                    data.historyImp.length > 0 ? (
                      <p className="accueilColTitle">
                        {t("home.history.adverseEffect.title")}
                      </p>
                    ) : (
                      <p className="accueilColTitle">
                        {t("home.history.adverseEffect.noTests")}
                      </p>
                    )}
                    {data.historyImp.map((prod, i) => {
                      const displayDate = moment(prod.date).format(
                        "Do MMMM YYYY",
                      );
                      return (
                        <Box
                          key={i}
                          sx={{ boxShadow: 1 }}
                          className="accueilBox"
                        >
                          <Grid>
                            <p className="accueilBoxDate">
                              {displayDate.toString()}
                            </p>
                            <p className="accueilBoxProd">{prod.nomProduit}</p>
                            <p className="accueilBoxType">
                              {t(`app:common.productTypeShort.${prod.type}`)}
                            </p>
                          </Grid>
                          <Grid>
                            <Box
                              bgcolor={
                                prod.resultat > 7
                                  ? "#DB211033"
                                  : prod.resultat > 4
                                  ? "#D7920033"
                                  : prod.resultat > 1
                                  ? "#0096FF33"
                                  : "#00000033"
                              }
                              color={
                                prod.resultat > 7
                                  ? "#DB2110"
                                  : prod.resultat > 4
                                  ? "#946500"
                                  : prod.resultat > 1
                                  ? "#0096FF"
                                  : "#000000"
                              }
                              className="accueilLien"
                            >
                              {prod.resultat > 7
                                ? t("home.adverseEffect.result.sureLink")
                                : prod.resultat > 4
                                ? t("home.adverseEffect.result.probableLink")
                                : prod.resultat > 1
                                ? t("home.adverseEffect.result.possibleLink")
                                : t("home.adverseEffect.result.noLink")}
                            </Box>
                            <div style={{ textAlign: "end" }}>
                              <NavigateNext
                                color="black"
                                onClick={() => this.showTest(prod)}
                              />
                            </div>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                ) : (
                  <p className="homeText">
                    <Trans i18nKey="home:home.history.loginMessage">
                      <Link to="/connexion">Sig up</Link>
                      <Link to="/auth">Log in</Link> to access your history
                    </Trans>
                  </p>
                )}

                {(pro || premium) && connected ? (
                  <Grid className="accueilDisplayCol">
                    {data.historyDecla.length !== undefined &&
                    data.historyDecla.length > 0 ? (
                      <p className="accueilColTitle">
                        {t("home.history.declaration.title")}
                      </p>
                    ) : (
                      <p className="accueilColTitle">
                        {t("home.history.declaration.noDeclarations")}
                      </p>
                    )}
                    {data.historyDecla.map((prod, i) => {
                      const displayDate = moment(prod.date).format(
                        "Do MMMM YYYY",
                      );
                      return (
                        <Box
                          key={i}
                          sx={{ boxShadow: 1 }}
                          className="accueilBox"
                        >
                          <Grid>
                            <p className="accueilBoxDate">
                              {displayDate.toString()}
                            </p>
                            <p className="accueilBoxProd">
                              {t(`home.history.productTypeShort.${prod.type}`)}
                            </p>
                            <p className="accueilBoxType">{prod.nomProduit}</p>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                ) : (
                  <>
                    {connected ? (
                      <p className="accueilColTitle">
                        {t("home.history.declaration.subscribe")}
                      </p>
                    ) : null}
                  </>
                )}
              </Grid>
            </div>
            <br />
            <hr />
            <div>
              <Grid className="accueilDisplayLegal">
                <Link to="/cgu" target="_blank">
                  {t("app:common.termsAndConditions")}
                </Link>
                <div>{" | "}</div>

                <Link to="/privacy" target="_blank">
                  {t("app:common.privacyPolicy")}
                </Link>
                <span className="version">
                  {config.appVersion}+{config.appBuild}
                </span>
              </Grid>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation("home")(HomePage);
