import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { VigicareLogoWhite } from '../../styles/Icons';
import { ThemeProvider, Grid, Button  } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Theme } from '../../styles/styles';
import Popup from 'reactjs-popup';

import { withTranslation, Trans } from 'react-i18next';
import moment from 'moment';

import { useMutation, useQuery } from '@tanstack/react-query';
import { newDeclaration } from '../../api/history';
import { getUser } from '../../api/user';

import Infos from '../sharedComponents/Infos';
import Pays from '../sharedComponents/Pays';
import DeclarationValidePDF from '../sharedComponents/DeclarationValidePDF';

import Patient from './forms/Patient';
import PatientNouveauNe from './forms/PatientNouveauNe';
import Antecedents from './forms/Antecedents';
import Medicaments from './forms/Medicaments';
import LotMedicament from './forms/LotMedicament';
import ProduitsSanguins from './forms/ProduitsSanguins';
import Effets from './forms/Effets';
import Gravite from './forms/Gravite';
import Evolution from './forms/Evolution';

import { neutralizeBack } from '../../misc/navigation';

import "./medicament.css";

class Medicament extends React.Component {
    constructor(props) {
        super(props);
        let queryUserdata = {};
        if (props.queryUser.isSuccess && this.props.queryUser.data !== undefined && this.props.queryUser.data.length > 0) {
            queryUserdata = {
                userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode, 
                    dateFin: this.props.queryUser.data[0].dateFin,
            }
        };
       if (props.params !== undefined && props.params !== null) {
            this.state = {
                activePage: 0,
                infoPage: false,
                userdata: {
                    sex: props.params.user.sex,
                },
                newbornData: {},
                background: "",
                medicaments: [
                    {
                        name: props.params.produit.name,
                        posology: '',
                        uniteDuree: 'par jour',
                        administrationRoute: '',
                        startDate: null,
                        endDate: null,
                        indication: '',
                    }
                ],
                lotMedicamentVaccin: {},
                produitSanguin: {},
                effets: {
                    description: props.params.effets.descEffet,
                    date: props.params.effets.dateEffet !== '' ? moment(props.params.effets.dateEffet) : null,
                },
                gravite: {
                    hospitalisation: props.params.gravite.hospitalisation,
                    incapacite: props.params.gravite.incapacite,
                    pronosticVital: props.params.gravite.risqueVital,
                    deces: props.params.gravite.deces,
                    anomalies: props.params.gravite.anomalies,                    
                    aucun: props.params.gravite.aucun,
                },
                evolution: {},
                queryUserdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            } 
        } else {
            this.state = {
                activePage: 0,
                infoPage: false,
                userdata: {},
                newbornData: {},
                background: "",
                medicaments: [],
                lotMedicamentVaccin: {},
                produitSanguin: {},
                effets: {},
                gravite: {},
                evolution: {},
                queryUserdata,
                connected: false,
                showAlert: false,
                closeConfirm: false,
                loading: false,
            } 
        }

        this.handlerPage = this.handlerPage.bind(this);
        this.handlerForm = this.handlerForm.bind(this);
        this.handlerDataNewborn = this.handlerDataNewborn.bind(this);
        this.handlerAntecedents = this.handlerAntecedents.bind(this);
        this.handlerMedicaments = this.handlerMedicaments.bind(this);
        this.handlerLotMedicament = this.handlerLotMedicament.bind(this);
        this.handlerProduitsSanguins = this.handlerProduitsSanguins.bind(this);
        this.handlerEffets = this.handlerEffets.bind(this);
        this.handlerGravite = this.handlerGravite.bind(this);
        this.handlerEvolution = this.handlerEvolution.bind(this);

        this.onClickInfo = this.onClickInfo.bind(this);
        this.openAlert = this.openAlert.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.backHome = this.backHome.bind(this);

    }
     
    componentDidMount() {
        this.props.queryUser.refetch();
    }

    componentDidUpdate(pP, pS) {
        if (!this.state.loading) {
            this.setState({ loading: true, showAlert: false }, () => {neutralizeBack(this.openAlert)})
        }
        if (!pS.connected && this.props.queryUser.data !== undefined && this.props.queryUser.data[0] !== undefined) {
            this.setState({
                connected: true, 
                userdata: {
                    userId: this.props.queryUser.data[0].userId,
                    pro: this.props.queryUser.data[0].proSante === 'true' ? true : false,
                    proLabel: this.props.queryUser.data[0].proSanteLabel,
                    email: this.props.queryUser.data[0].email,
                    periode: this.props.queryUser.data[0].periode,
                    dateFin:  this.props.queryUser.data[0].dateFin
            }});
        }
        if (!pS.closeConfirm && this.state.closeConfirm) {
            this.props.navigation('/');
        }
    }

    handlerPage() {
        let newPage = this.state.activePage + 1;
        this.setState({ activePage: newPage, showAlert: false })
    }
    handlerForm(userdata) {
        let newPage = this.state.activePage + 1;
        this.setState({ userdata, activePage: newPage })
    }
    handlerDataNewborn(newbornData) {
        let newPage = this.state.activePage + 1;
        this.setState({ newbornData, activePage: newPage })
    }
    handlerAntecedents(text) {
        let newPage = this.state.activePage + 1;
        this.setState({ background: text, activePage: newPage })
    }
    handlerMedicaments(medicaments) {
        let newPage = this.state.activePage + 1;
        this.setState({ medicaments, activePage: newPage })
    }
    handlerLotMedicament(lotMedicamentVaccin) {
        let newPage = this.state.activePage + 1;
        this.setState({ lotMedicamentVaccin, activePage: newPage })
    }
    handlerProduitsSanguins(data){
        let newPage = this.state.activePage + 1;
        this.setState({ produitSanguin: data, activePage: newPage })
    }
    handlerEffets(data){
        let newPage = this.state.activePage + 1;
        this.setState({ effets: data, activePage: newPage })
    }
    handlerGravite(data){
        let newPage = this.state.activePage + 1;
        this.setState({ gravite: data, activePage: newPage })
    }
    handlerEvolution(data){
        let newPage = this.state.activePage + 1;
        this.props.mutationCreateDeclaration.mutate({ type: "Médicament", nomProduit: this.state.medicaments[0].name, effets: this.state.effets.description });
        this.setState({ evolution:data, activePage: newPage })
    }

    onClickReturn() {
        if (this.state.infoPage) this.setState({ infoPage: false })
        else if (this.state.activePage > 0) { 
            let newPage = this.state.activePage - 1;
            this.setState({ activePage: newPage })
        }
    }

    onClickInfo() { this.setState({ infoPage: !this.state.infoPage }) }

    openAlert() { this.setState({ showAlert: true }) }

    closeAlert() { this.setState({ showAlert: false }) }

    backHome() {
        this.setState({ showAlert: false, closeConfirm: true });
    }
    render () {
        const { activePage, showAlert } = this.state;

        return <ThemeProvider theme={Theme}>
            <Grid className="mainApp">
                <header className='headerApp'>
                    <Grid className='headerDeclaration'>
                        {activePage === 0 ?
                            <VigicareLogoWhite />
                        : <>{activePage === 10 ?
                            <VigicareLogoWhite />
                        : <div className='headerDeclarationLeft'>
                            <svg className="returnArrow" onClick={() => this.onClickReturn()} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <VigicareLogoWhite />
                        </div>}</>
                    }
                        <div className='headerDeclarationCenter'>
                            <div className='headerText'><Trans>Déclaration d'effet indésirable</Trans></div>
                            <svg className="infoBtn" style={{ marginLeft: '0.25em'}} onClick={() => {this.onClickInfo()}} width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z" fill="#ffffff"/>
                            </svg>
                        </div>
                        {showAlert ?
                            <div onClick={() => this.closeAlert()}>
                                <HomeIcon color="white" />
                            </div> :
                            <div onClick={() => this.openAlert()}>
                                <HomeIcon color="white" />
                            </div>
                        }
                        
                    </Grid>
                </header>
                <div className='mainDeclaration'>
                    { this.state.infoPage ? 
                    <Infos handler={this.onClickInfo}></Infos> 
                        : <>{ activePage === 0 ? <Pays handler={this.handlerPage} page={activePage * 10 + 10}></Pays>
                            : <>{activePage === 1 ? <Patient data={this.state.userdata} handler={this.handlerForm} page={activePage * 10 + 10}></Patient> 
                                : <>{activePage === 2 ? <PatientNouveauNe data={this.state.newbornData} handler={this.handlerDataNewborn} page={activePage * 10 + 10}></PatientNouveauNe> 
                                    : <> {activePage === 3 ? <Antecedents data={this.state.background}  handler={this.handlerAntecedents} page={activePage * 10 + 10 }></Antecedents> 
                                        : <> {activePage === 4 ? <Medicaments data={this.state.medicaments} handler={this.handlerMedicaments} page={activePage * 10 + 10}></Medicaments> 
                                            : <> {activePage === 5 ? <LotMedicament data={this.state.lotMedicamentVaccin} handler={this.handlerLotMedicament} page={activePage * 10 + 10}></LotMedicament> 
                                                : <> {activePage === 6 ? <ProduitsSanguins data={this.state.produitSanguin} handler={this.handlerProduitsSanguins} page={activePage * 10 + 10}></ProduitsSanguins> 
                                                    : <> {activePage === 7 ? <Effets data={this.state.effets} handler={this.handlerEffets} page={activePage * 10 + 10}></Effets> 
                                                        : <> {activePage === 8 ? <Gravite data={this.state.gravite} handler={this.handlerGravite} page={activePage * 10 + 10}></Gravite> 
                                                            : <> {activePage === 9 ? <Evolution data={this.state.evolution} handler={this.handlerEvolution} page={activePage * 10 + 10}></Evolution> 
                                                                : <> {activePage === 10 ? <DeclarationValidePDF data={this.state}></DeclarationValidePDF> 
                                                                    : <>
                                                                    </> }
                                                                </> }
                                                            </> }
                                                        </> }
                                                    </> }
                                            
                                                </> }
                                            </> }
                                        </> }                                       
                                    </>}
                                </>}
                            </>
                        }</>
                    }
                    <Popup open={showAlert && activePage < 10} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b><Trans>Attention</Trans></b></p>
                            </div>
                                <p><Trans>En retournant à l'accueil, vous allez perdre les informations entrées dans ces formulaires</Trans></p>
                                <p><i><Trans>Vous pouvez revenir aux formulaires précédents avec la flèche présente dans le bandeau de navigation</Trans></i></p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.backHome()} variant="outlined" color="error"><Trans>Retour à l'accueil</Trans></Button>
                                <Button onClick={() => this.closeAlert()} variant="contained"><Trans>Je continue</Trans></Button>
                            </div>
                        </Grid>
                    </Popup>
                    <Popup open={showAlert && activePage === 10} position="center" onClose={() => this.closeAlert()} contentStyle={{minWidth: '80%'}}>
                        <Grid className='infoPanel'>
                            <div className='infoTitle'>
                                <p><b><Trans>Attention</Trans></b></p>
                            </div>
                                <p>Etes vous surs d'avoir enregistré votre déclaration avant de revenir à l'accueil ?</p>
                            <div className='infoTitle'>
                                <Button onClick={() => this.closeAlert()} variant="contained" color="error"><Trans>Non</Trans></Button>
                                <Button onClick={() => this.backHome()} variant="outlined"><Trans>Oui</Trans></Button>
                            </div>
                        </Grid>
                    </Popup>
                </div>
            </Grid>
        </ThemeProvider>
    }
};

export default withRouter(withQuery(withTranslation()(Medicament)));

function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation(); 
        const navigate = useNavigate(); 
        if (location.state !== null) return <Child { ...props } params={location.state} navigation={navigate} />;
        else return <Child { ...props } navigation={navigate} />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const queryUser = useQuery(['fetch-user'], () => getUser().then((r) => {return r.data}).catch(e =>  {throw e}));

        const mutationCreateDeclaration = useMutation({
            mutationFn: (data) => {
                return newDeclaration(data).then((r) => {return r.data}).catch(e =>  {throw e})
            },
            onSuccess: () => {
            }
        });

        return <Child { ...props } mutationCreateDeclaration={mutationCreateDeclaration} queryUser={queryUser} />;
    }
}