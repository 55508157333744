import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Grid, Button, Box, Radio } from "@mui/material";
import { InfoIconBlue, AlertIcon } from "../../../../styles/Icons";

export const Symptoms = ({ data, handleChange, handlePrev, handleNext }) => {
  useTranslation();

  const questions = [
    {
      id: "symptomesProduit",
      question: (
        <Trans>
          Les symptômes peuvent-ils être liés à l'utilisation du produit ? (ex :
          effet au site d'application, réaction digestive pour un produit
          avalé...)
        </Trans>
      ),
      message: (
        <Trans>
          La symptomatologie est évocatrice de l'utilisation d'un produit lorsque
          les symptômes observés concordent en termes de localisation, d'effet ou d'évolution
          avec la nature ou le mode d'utilisation du produit.
        </Trans>
      )
    },
    {
      id: "arretEffet",
      question: (
        <Trans>
          L'évènement indésirable s'est-il amélioré après l'arrêt du produit ou
          l'administration d'un antidote spécifique ?
        </Trans>
      ),
      message: (
        <Trans>Ne pas arrêter son traitement prescrit sans avis médical.</Trans>
      )
    },
    {
      id: "retourEffet",
      question: (
        <Trans>
          L'évènement indésirable est-il réapparu lorsque le produit a été
          administré de nouveau ?
        </Trans>
      ),
    },
    {
      id: "autresCauses",
      question: (
        <Trans>
          Y a-t-il d'autres causes qui pourraient avoir provoqué la réaction (un
          autre traitement, symptômes de maladie en cours..) ?
        </Trans>
      ),
    },
    {
      id: "retourEffetAutreProduit",
      question: (
        <Trans>
          La réaction est-elle réapparue après l'administration d'un autre
          produit (pas le produit suspecté) ?
        </Trans>
      ),
    },
    {
      id: "produitAnalyses",
      question: (
        <Trans>
          Le produit a-t-il été retrouvé à des concentrations supérieures à la
          normale lors d'analyses sanguines, salivaires ou autres ?
        </Trans>
      ),
    },
    {
      id: "reactionDose",
      question: (
        <Trans>
          La réaction s'est-elle aggravée en augmentant la dose, et améliorée en
          diminuant la dose ?
        </Trans>
      ),
      message: (
        <Trans>Ne pas modifier les posologies prescrites par votre professionnel de santé.</Trans>
      )
    },
    {
      id: "reactionSimilaire",
      question: (
        <Trans>
          Est-ce qu'une réaction similaire à ce produit (ou à un autre produit
          du même type) a déjà été observé lors d'une précédente exposition ?
        </Trans>
      ),
    },
    {
      id: "confirmationMedicale",
      question: (
        <Trans>
          L'évènement indésirable a-t-il été confirmé par des données objectives
          (tests en laboratoire, radio, bilan sanguin...) ?
        </Trans>
      ),
    },
  ];

  const options = [
    { label: <Trans>Oui</Trans>, value: true },
    { label: <Trans>Non</Trans>, value: false },
    { label: <Trans>Inconnu ou non testé</Trans>, value: "inconnu" },
  ];

  const handleQuestionnaireChange = (id, option) => {
    handleChange({ ...data, [id]: option });
  };

  const renderIcon = (index) => {
    switch(index){
      case 0:
       return <InfoIconBlue/>
      default:
        return <AlertIcon/>;
    }
  }

  return (
    <>
      {questions.map((questionnaire, index) => (
        <section className="question" key={questionnaire.id}>
          <h2 className="questionnaireQuestion">
           {questionnaire.question}
          </h2>
          {questionnaire.message ?
            <div className={index === 0 ? "infoQuestion" : "alertQuestion"}>
             {renderIcon(index)}
              <p>{questionnaire.message}</p>
            </div> : null}
          {options.map((option, idx) => (
            <Grid key={idx} className="inputCheckboxRow">
              <label className="robotoFont">
                <Radio
                  name={questionnaire.id}
                  checked={data[questionnaire.id] === option.value}
                  onChange={() =>
                    handleQuestionnaireChange(questionnaire.id, option.value)
                  }
                />
                {option.label}
              </label>
            </Grid>
          ))}
        </section>
      ))}

      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
          <Button color="inherit" onClick={handlePrev} sx={{ mr: 1 }}>
            <Trans>Retour</Trans>
          </Button>

          <Button
            variant="contained"
            color="blue"
            onClick={handleNext}
            disabled={
              data.symptomesProduit === null ||
              data.arretEffet === null ||
              data.retourEffet === null ||
              data.autresCauses === null ||
              data.retourEffetAutreProduit === null ||
              data.produitAnalyses === null ||
              data.reactionDose === null ||
              data.reactionSimilaire === null ||
              data.confirmationMedicale === null
            }
          >
            <Trans>Suivant</Trans>
          </Button>
        </Box>
      </Grid>
    </>
  );
};
