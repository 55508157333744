import { Box, Button, Grid, MenuItem, Radio, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const ProfessionForm = ({ data, handleChange, handleNext }) => {
  const { t } = useTranslation("adverseEffect");

  const navigate = useNavigate();
  
  const handleChangeInput = (e) => {
    switch (e.target.id) {
      case "proSanteTrue":
        handleChange({ ...data, proSante: true });
        break;
      case "proSanteFalse":
        handleChange({ ...data, proSante: false });
        break;
      case "proSanteAutre":
        handleChange({ ...data, proSanteAutre: e.target.value });
        break;
      default:
        //default condition
        break;
    }
  };

  const handleChangeDropdown = (e) => {
    handleChange({ ...data, proSanteLabel: e.target.value });
  };

  return (
    <Grid className="professionForm">
      <p>
      {t("adverseEffect.professionForm.title")}
      </p>
      <Grid className="inputRadioRow">
        <label className="robotoFont">
          <Radio
            id="proSanteTrue"
            checked={data.proSante === true}
            onChange={handleChangeInput}
          />
          {t("adverseEffect.option.yes")}
        </label>
        <label className="robotoFont">
          <Radio
            id="proSanteFalse"
            checked={data.proSante === false}
            onChange={handleChangeInput}
          />
          {t("adverseEffect.option.no")}
        </label>
      </Grid>
      {data.proSante === true ? (
        <Grid className="formRowDouble" sx={{ marginTop: "24px" }}>
          <TextField
            fullWidth
            id="proSanteLabel"
            select
            value={data.proSanteLabel}
            onChange={handleChangeDropdown}
            label={t("adverseEffect.professionForm.label.profession")}
          >
            <MenuItem value="Médecin">
              {t("app:common.profession.doctor")}
            </MenuItem>
            <MenuItem value="Chirurgien-Dentiste">
              {t("app:common.profession.dentist")} 
            </MenuItem>
            <MenuItem value="Pharmacien">
              {t("app:common.profession.pharmacist")}
            </MenuItem>
            <MenuItem value="Sage-Femme">
              {t("app:common.profession.midwife")}
            </MenuItem>
            <MenuItem value="Autre">
              {t("app:common.profession.other")}
            </MenuItem>
          </TextField>
          {data.proSanteLabel === "Autre" ? (
            <TextField
              fullWidth
              id="proSanteAutre"
              type="text"
              value={data.proSanteAutre}
              label={t("adverseEffect.professionForm.label.specify")}
              onChange={handleChangeInput}
            />
          ) : null}
        </Grid>
      ) : null}
      <Box className="bottomBtnForm">
        <Button color="inherit" onClick={() => navigate("/")} sx={{ mr: 1 }}>
          {t("adverseEffect.action.back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={
            data.proSante === null ||
            (data.proSante && data.proSanteLabel === "") ||
            (data.proSante &&
              data.proSanteLabel === "Autre" &&
              data.proSanteAutre === "")
          }
        >
          {t("adverseEffect.action.next")}
        </Button>
      </Box>
    </Grid>
  );
};
