import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { formatNumber } from '../../utils/formatNumber';

import { Grid } from '@mui/material';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Pie } from "react-chartjs-2";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import { CrossIcon } from '../../styles/Icons';

const geoUrl = "/featuresContinent.json";



const optionsBar = {
    maintainAspectRatio : false,
    plugins: {
        legend: {
          display: false,
        },
    },
};

const optionsPie = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
          position: 'right',
        },
    },
};

const optionsHBar = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
        legend: {
            display: false,
        },
    },
  };

const getContinentColor = (nb, data, continent) => {
    const maxObservations = Math.max(...nb);
    const continentNb = data.find(e => e.name === continent);
    if (continentNb !== undefined && continentNb.nb !== undefined) {
        let opacity = Math.floor(continentNb.nb / maxObservations * 255).toString(16).toUpperCase();
    
        if (opacity.length === 1) opacity = "0" + opacity;
        return "#165e61" + opacity;    
    } else return "#165e6100";
}
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Legend, Tooltip);

class FicheProduit extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            name: props.data.productName,
            nbEffets: props.data.product.SexObservations.reduce((accumulator, e) => {return accumulator + e.NumberOfReports}, 0),
            dataBar: {
                labels: props.data.product.SexObservations.map((e) => e.Group),
                datasets: [{
                    data: props.data.product.SexObservations.map((e) => e.NumberOfReports),
                    backgroundColor: ['#071B2E', '#239497', '#AAAAAA'],
                }]
            },
            dataPie: {
                labels: props.data.product.AgeGroupObservations.map((e) => e.Group),
                datasets: [
                    {
                        data: props.data.product.AgeGroupObservations.map((e) => e.NumberOfReports),
                        backgroundColor: ['#942323', '#239497', '#F4BF4E', '#F28F8F', '#4D77FF', '#F56E46', '#AA6550', '#CCCCCC','#071B2E'],
                    },
                ],
            },
            dataHBar: {
                labels: props.data.product.AdrObservations.map((e) => e.Group.replace('disorders', 'dis.')),
                datasets: [
                    {
                        data: props.data.product.AdrObservations.map((e) => e.NumberOfReports),
                        backgroundColor: ['#239497'],
                    },
                ],
            },
            dataContinentsMap: props.data.product.ContinentObservations.map((e) => e.NumberOfReports),
            dataContinentsMapFull: props.data.product.ContinentObservations.map((e) => {return {name: e.Group, nb: e.NumberOfReports}}),
        };
    }

    render() {
        const { name, dataBar, nbEffets, dataPie, dataHBar, dataContinentsMap, dataContinentsMapFull } = this.state;
        const formattednbEffets = formatNumber(nbEffets);
        
        return <>
            <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>  
                <p className='productTitle'>{name}</p>
                <div onClick={this.props.handler}>
                    <CrossIcon />
                </div>
            </Grid>
            <Grid sx={{ textAlign: 'end', fontSize: '12px' }}><Trans>Données fournies par</Trans> WHO Global ICSR database - VigiBase</Grid>
            <br />
            <div className='productDisplay'>
                <Grid sx={{ width:'50%', height: '10em' }}>
                    <h5 className='productSubtitles'><Trans>Effets indésirables</Trans></h5>
                    <p className='productDetail'><Trans>Effets indésirables différents recensés suite à l'utlisation de ce produit</Trans></p>
                    <p className='productData'>{formattednbEffets}</p>
                    <br />
                </Grid>
                <Grid sx={{ width:'50%', height: '20em' }}>
                    <h5 className='productSubtitles'><Trans>Ratio homme - femme</Trans></h5>
                    <p className='productDetail'></p>
                    <div className='productChart'>
                        <Bar style={{ width:'40%', alignSelf: 'center'}} options={optionsBar} data={dataBar}  />
                    </div>
                </Grid>
                </div>
                <div className='productDisplay'>
                    <Grid>

                        <h5 className='productSubtitles'><Trans>Proportion par tranche d'âge</Trans></h5>
                        <p className='productDetail'></p>
                        <div className='productChart'>
                            <Pie style={{ width:'40%', height: '20em' }} options={optionsPie} data={dataPie} />
                        </div>
                    </Grid>
                    <Grid>
                        <h5 className='productSubtitles productList'><Trans>Nombre d'effets indésirables en fonction de la classification</Trans></h5>
                        <p className='productDetail'></p>
                        <div className='productChart'>
                            <Bar style={{ width:'40%', height: '35em' }} options={optionsHBar} data={dataHBar}  />
                        </div>
                    </Grid>
                </div>
            
            <Grid className='productMap'>
                <div>
                    <h5 className='productSubtitles'><Trans>Taux d'incidence par continent</Trans></h5>
                    <p className='productDetail'></p>
                    
                    <div className='productChart'>
                        <div className='productMapLegend'>
                            {dataContinentsMapFull.sort(function (a, b) {
                                return a.nb - b.nb;
                                }).reverse().map((e, i) => {
                                    return (<div key={i}><Trans>{e.name}</Trans> <br /> <b>{e.nb}</b> </div>)
                                })
                            }
                        </div>
                        <ComposableMap
                            projection="geoEqualEarth"
                            projectionConfig={{
                                rotate: [-20, -5, 0],
                                scale: 115,
                            }}
                            viewBox='100 100 600 400'>
                                
                            <Geographies geography={geoUrl}>
                                {({ geographies }) =>
                                    geographies.map((geo) => (
                                        <Geography key={geo.rsmKey} geography={geo} stroke="#165e6140" strokeWidth={1} fill={getContinentColor(dataContinentsMap, dataContinentsMapFull, geo.properties.name)} style={{ default: {outline: 'none'}, hover: {outline: 'none'}, pressed: {outline: 'none'} }} />
                                    ))
                                }

                            </Geographies>
                        </ComposableMap>
                        
                    </div>
                </div>
            </Grid>
        </>
    }
};

export default withTranslation()(FicheProduit);


