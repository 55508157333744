import axios from 'axios';
import { config } from '../config';

const instance = axios.create({
  withCredentials: true
});

const BASE_URL = config.apiBaseUrl

export const deleteSubscription = async () => {
    return instance.delete(`${BASE_URL}/premium`);
}

export const getUserCardDetail = async () => {
  return instance.get(`${BASE_URL}/premium/user`);
}

export const newCheckoutMonthly = async () => {
  return instance.post(`${BASE_URL}/checkout-session/month`);
}

export const newCheckoutAnnual = async () => {
  return instance.post(`${BASE_URL}/checkout-session/year`);
}

export const validateSubscription = async (data) => {
  return instance.post(`${BASE_URL}/checkout-session/validation`, data);
}

export const getProductPrices = async () => {
  return instance.get(`${BASE_URL}/premium-price`)
}

export const reviveSubscription = async () => {
  return instance.put(`${BASE_URL}/premium`)
}
