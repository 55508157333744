import React from "react";
import { useTranslation } from "react-i18next";

export const UserCardDetail = ({ userCardDetail }) => {
  const { t } = useTranslation("profile")

  return (
    <>
      {userCardDetail.map((user) => {
        return <div className="customer-card-detail" key={user.id}>
          <span>{user.card.brand.toUpperCase()}</span>
          <span className="card-number">{user.card.number}</span>
          <div>
            <span>{t("profile.mySubscription.payment.valid")}</span>
            <span>{user.card.expirationMonth.toString().padStart(2, '0')}/{user.card.expirationYear.toString().slice(-2)}</span>
          </div>
          <span>{user.name}</span>
        </div>
      })}
    </>
  )
}
