import React from 'react';

import { Grid, Box, Button, CircularProgress  } from '@mui/material';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTranslation } from "react-i18next"; 

import { useMutation } from '@tanstack/react-query';
import { validateSubscription } from '../../api/premium';

import './premium.css';
import { CrossIcon } from '../../styles/Icons';



class PremiumSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: null,
            loader: false,
            navig: false,
        }
    }

    componentDidMount() {
        if (this.props.querySubscriptionValidation.isIdle && !this.state.loader) {
            this.props.querySubscriptionValidation.mutate({ sessionId: this.props.sessionId });
            this.setState({ loader: true });
        }
    }

    componentDidUpdate() {
        if (!this.state.loader) {
            this.props.querySubscriptionValidation.mutate({ sessionId: this.props.sessionId });
            this.setState({ loader: true });
        }
        if (this.props.querySubscriptionValidation.isSuccess && this.state.success !== true) { 
            this.setState({ success: true });
        }
        if (this.state.navig === true) {
            this.props.handler();
            this.props.navigation('/recherche');
        }
    }

    render () {
        const { success } = this.state;
        const { t } = this.props;
        const isLoading = this.props.querySubscriptionValidation.isLoading;
        return <Grid className="premiumPopup premiumApp">
                <Grid>
                    <Grid className='premiumTopRow'>
                        <Button disabled sx={{ color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important', fontSize: '10px' }}><AutoAwesomeRoundedIcon fontSize='12px' color='white' />{t("app:common.vigicarePremium")}</Button>
                        <div onClick={this.props.handler}><CrossIcon color='black' /></div>
                    </Grid>
                
                </Grid>
                {isLoading ? 
                    <CircularProgress />
                    : <>{success ? <>
                            <p className='successPremiumTitle'>{t("premium.subscription.message.success")} 🎉</p>
                            <p className='successPremiumLabel'>{t("premium.subscription.message.search")}</p>
                            <Box className='successPremiumBtn'>
                                <Button onClick={() => this.setState({ navig: true })} sx={{ color: 'white !important', background: 'linear-gradient(90deg, #F46B45 0%, #EEA849 100%)', textTransform: 'unset !important' }}>{t("premium.action.search")}</Button>
                            </Box>

                        </> : 
                            <>{!success ? <p className='errorText'>{t("premium.subscription.message.error")}</p> : null}</> 
                    }</>
                }
            </Grid>
    }
}

export default withRouter(withQuery(withTranslation("premium")(PremiumSuccess)));

function withRouter( Child ) {
    return ( props ) => {
        const [searchParams] = useSearchParams(); 
        const sessionId = searchParams.get("session_id");
  
        const navigate = useNavigate();
        return <Child { ...props } sessionId ={ sessionId } navigation={navigate}  />;
    }
}

function withQuery( Child ) {
    return ( props ) => {
        const querySubscriptionValidation = useMutation({
            mutationFn: (data) => {
                return validateSubscription(data).then((r) => {return r.data}).catch(e =>  {throw e})
            },
        });
        
        return <Child { ...props } querySubscriptionValidation={querySubscriptionValidation} />;
    }
}
