import React from 'react';
import { CrossIcon } from '../../styles/Icons';
   
export default class Infos extends React.Component {
    onClickBack() {
        this.props.handler();
    }
    
    render() {

        return <div className="infoPanel">
            <div onClick={() => this.onClickBack()} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <CrossIcon />
            </div>
            <p>Les informations recueillies seront, dans le respect du secret médical, informatisées et communiquées au Centre régional de pharmacovigilance (CRPV) et à l'Agence nationale de sécurité du médicament et des produits de santé (Ansm).</p>

            <p>Conformément aux articles 34 et 38 à 43 de la loi n° 78-17 relative à l'informatique, aux fichiers et aux libertés, <b><u>le CRPV veillera à assurer la confidentialité des données mentionnées sur cette déclaration.</u></b> </p>

            <p>Par ailleurs, le patient dispose d'un droit d'accès auprès du CRPV, lui permettant d'avoir connaissance de la totalité des informations saisies le concernant et de corriger d'éventuelles données inexactes, incomplètes ou équivoques.</p> 
        </div>
    }
}