import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { Trans, withTranslation } from "react-i18next";   
import { VigicareLogoWhiteXL, VigicareLogoBlack } from '../../styles/Icons';

class InscriptionValide extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};

        window.scrollTo(0, 0);
    }

    render() {
        return <>
            <div className='accueilDisplayLogo'>
                <VigicareLogoWhiteXL />
                <VigicareLogoBlack />
            </div>
            <p className="authTitle"><Trans>Inscription réussie !</Trans></p>
            <p>
                <Trans>Vous avez reçu un mail pour valider votre inscription</Trans> 
            </p>
            <Link to='/auth' className="linkButton"></Link>
            <Box className='bottomBtnQuestionnaire bottomBtnCo'>
               <Link className='linkButton' to={"/auth"}><Button fullWidth className="fullBtn" variant="contained" color="blue"><Trans>Connectez-vous</Trans></Button></Link>
            </Box>
        </>
    }
}

export default withTranslation()(InscriptionValide);