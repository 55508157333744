import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Button, Grid  } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';

import { emailContactFR } from '../../misc/dataset';
import { MedicamentPDF, CosmetiquePDF, DispositifPDF, ComplementPDF } from '../../misc/templatePDF';
import { withTranslation } from 'react-i18next';

class DeclarationValidePDF extends React.Component {
    constructor (props) {
        super(props);
        let name = '';

        switch (props.location) {
            case '/declaration/medicament': { name = props.data.medicaments.length > 0 && props.data.medicaments[0] !== undefined ? props.data.medicaments[0].name : 'Erreur Saisie'; break; }
            case '/declaration/cosmetique': { name = props.data.produitCosmetique.nomComplet !== undefined ? props.data.produitCosmetique.nomComplet : 'Erreur Saisie'; break; } 
            case '/declaration/complement': { name = props.data.produitAlim !== undefined && props.data.produitAlim[0] !== undefined ? props.data.produitAlim[0].nomCom : 'Erreur Saisie'; break; } 
            case '/declaration/dispositif': { name = props.data.dispoMed.denominationDM !== undefined ? props.data.dispoMed.denominationDM : 'Erreur Saisie'; break; } 
            default: {name= 'Erreur Saisie'; break;}
        }
        if (props.data !== undefined && Object.keys(props.data).length !== 0) {
            this.state = {
                data: props.data,
                type: props.location,
                name,
            };
        } else {
            this.state = {
                data: {},
                type: props.location,
                name,
            };
        }

        this.downloadPDF = this.downloadPDF.bind(this);
    }

    async downloadPDF() {
        const {type, name, data} = this.state;
        let fileName = '';
        let filePDF;

        if (type === '/declaration/medicament') {
            filePDF = await MedicamentPDF(data);
            fileName = "Déclaration Effet Indésirable - Médicament " + name + ".pdf";
        }
        else if (type === '/declaration/cosmetique') {
            filePDF = await CosmetiquePDF(data);
            fileName = "Déclaration Effet Indésirable - Cosmétique " + name + ".pdf";
        }
        else if (type === '/declaration/complement') {
            filePDF = await ComplementPDF(data);
            fileName = "Déclaration Effet Indésirable - Complément alimentaire " + name + ".pdf";   
        }
        else if (type === '/declaration/dispositif') {
            filePDF = await DispositifPDF(data);
            fileName = "Déclaration Effet Indésirable - Dispositif Médical " + name + ".pdf";
        }

        const blob = new Blob([filePDF], {type: "application/pdf"});
        let tempLink = document.createElement('a');
        tempLink.href = URL.createObjectURL(blob);
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        URL.revokeObjectURL(blob);
    }

    render() {
        const { data, type } = this.state;

        return <>
            <p className="validationTitle"><Trans>Merci pour votre déclaration</Trans></p>
            <p className="validationSteps"><Trans>Les prochaines étapes :</Trans></p>
            <Grid className="validationText">
                <p>
                    <li><Trans>Télécharger le formulaire rempli</Trans></li>
                </p>
                <Grid className="boxBtn">
                    {data !== undefined && data !== {} ?
                        <>{type === '/declaration/medicament' ?
                            <>
                                <Box onClick={this.downloadPDF}>
                                    <Button className="fullBtn" variant="contained" color="blue">
                                            <FileDownloadOutlined fontSize="small" sx={{ paddingRight: "12px" }} />
                                            <Trans>TÉLÉCHARGER LE FORMULAIRE</Trans>
                                    </Button>
                                </Box>
                                <p>
                                    <li><Trans>L'envoyer par mail à l'adresse suivante :</Trans></li>
                                </p>
                                <Grid>{emailContactFR(data.effets.dept)}</Grid>
                                <Grid className='premiumBoxLink'>
                                    <p className='premiumSmallText'>PREMIUM</p>
                                    <p className='premiumTitle'><Trans>Recherchez n'importe quel produit</Trans></p>
                                    <p className='searchBoxVoie'><Trans>Découvrez si le produit a déjà provoqué des effets indésirables en vie réelle</Trans></p>
                                    <Link to='/premium' className="linkButton">
                                        <Button sx={{
                                            color: 'white',
                                            background: 'linear-gradient(111.87deg, #F46B45, #EEA849)',
                                            marginTop: '24px',
                                        }} variant="contained">
                                            <Trans>Devenir membre</Trans>
                                        </Button>
                                    </Link>
                                </Grid>
                            </>
                            : <>{type === '/declaration/cosmetique' ?
                                <>
                                    <Box onClick={this.downloadPDF}>
                                        <Button className="fullBtn" variant="contained" color="blue">
                                                <FileDownloadOutlined fontSize="small" sx={{ paddingRight: "12px" }} />
                                                <Trans>TÉLÉCHARGER LE FORMULAIRE</Trans>
                                        </Button>
                                    </Box>
                                    <p>
                                        <li><Trans>L'envoyer par mail à l'adresse suivante :</Trans></li>
                                    </p>
                                    <Grid><a className="mailAdressEndForm" data-rel="external" href="mailto:cosmetovigilance@ansm.sante.fr?subject=Déclaration Cosmétique" target="_blank" rel="noreferrer">cosmetovigilance@ansm.sante.fr</a></Grid>
                                    <Grid className='premiumBoxLink'>
                                        <p className='premiumSmallText'>PREMIUM</p>
                                        <p className='premiumTitle'><Trans>Recherchez n'importe quel produit</Trans></p>
                                        <p className='searchBoxVoie'><Trans>Découvrez si le produit a déjà provoqué des effets indésirables en vie réelle</Trans></p>
                                        <Link to='/premium' className="linkButton">
                                            <Button sx={{
                                                color: 'white',
                                                background: 'linear-gradient(111.87deg, #F46B45, #EEA849)',
                                                marginTop: '24px',
                                            }} variant="contained">
                                                <Trans>Devenir membre</Trans>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </>
                                : <> {type === '/declaration/complement' ?
                                    <>
                                        <Box onClick={this.downloadPDF}>
                                            <Button className="fullBtn" variant="contained" color="blue">
                                                    <FileDownloadOutlined fontSize="small" sx={{ paddingRight: "12px" }} />
                                                    <Trans>TÉLÉCHARGER LE FORMULAIRE</Trans>
                                            </Button>
                                        </Box>
                                        <p>
                                            <li><Trans>L'envoyer par mail à l'adresse suivante :</Trans></li>
                                        </p>
                                        <Grid><a className="mailAdressEndForm" data-rel="external" href="mailto:nutrivigilance@anses.fr?subject=Déclaration Nutrivigilance" target="_blank" rel="noreferrer">nutrivigilance@anses.fr</a></Grid>
                                        <Grid className='premiumBoxLink'>
                                            <p className='premiumSmallText'>PREMIUM</p>
                                            <p className='premiumTitle'><Trans>Recherchez n'importe quel produit</Trans></p>
                                            <p className='searchBoxVoie'><Trans>Découvrez si le produit a déjà provoqué des effets indésirables en vie réelle</Trans></p>
                                            <Link to='/premium' className="linkButton">
                                                <Button sx={{
                                                    color: 'white',
                                                    background: 'linear-gradient(111.87deg, #F46B45, #EEA849)',
                                                    marginTop: '24px',
                                                }} variant="contained">
                                                    <Trans>Devenir membre</Trans>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </>
                                    : <>{type === '/declaration/dispositif' ? 
                                        <>
                                            <Box onClick={this.downloadPDF}>
                                                <Button className="fullBtn" variant="contained" color="blue">
                                                        <FileDownloadOutlined fontSize="small" sx={{ paddingRight: "12px" }} />
                                                        <Trans>TÉLÉCHARGER LE FORMULAIRE</Trans>
                                                </Button>
                                            </Box>
                                            <p>
                                                <li><Trans>L'envoyer par mail à l'adresse suivante :</Trans></li>
                                            </p>
                                            <Grid><a className="mailAdressEndForm" data-rel="external" href="mailto:nutrivigilance@anses.fr?subject=Déclaration Nutrivigilance" target="_blank" rel="noreferrer">materiovigilance@ansm.sante.fr</a></Grid>
                                            <Grid className='premiumBoxLink'>
                                                <p className='premiumSmallText'>PREMIUM</p>
                                                <p className='premiumTitle'><Trans>Recherchez n'importe quel produit</Trans></p>
                                                <p className='searchBoxVoie'><Trans>Découvrez si le produit a déjà provoqué des effets indésirables en vie réelle</Trans></p>
                                                <Link to='/premium' className="linkButton">
                                                    <Button sx={{
                                                        color: 'white',
                                                        background: 'linear-gradient(111.87deg, #F46B45, #EEA849)',
                                                        marginTop: '24px',
                                                    }} variant="contained">
                                                        <Trans>Devenir membre</Trans>
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </>
                                        : <></>
                                    }</>
                                }</>
                            }</>
                        }</>
                    : <></>}
                </Grid>
                
            </Grid>
            <Box className="bottomBtn">
                <Link to='/' className="linkButton"><Button variant="contained" color="blue"><Trans>Retour Accueil</Trans></Button></Link>
            </Box>
        </>
    }
};

export default withRouter(withTranslation()(DeclarationValidePDF));


function withRouter( Child ) {
    return ( props ) => {
        const location = useLocation(); 
        return <Child { ...props } location={ location.pathname } />;
    }
};